import React from "react";

const ShowErrors = ({errors}) => {
    if (errors) {
        let x = 1

        return <tr className="danger">
            <td colSpan={10}>
                <ul>
                    {errors.map(item => { return <li key={x++}>{item}</li> })}
                </ul>
            </td>
        </tr>
    } else {
        return <></>
    }
}

export default ShowErrors
