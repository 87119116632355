import React, { forwardRef } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const commonStyles = {
    cellStyle: {
        fontSize: 13,
    },
    headerStyle: {
        fontSize: 15,
    },
    searchFieldStyle: {
        fontSize: 13,
    },
};

class ProfileRelatedResources extends React.Component {
    constructor(props) {
        super(props);

        this.translations = { ...props.componentTranslations };
        this.materialTableTranslations = { ...props.materialTableTranslations };

        this.state = {
            walletsLoading: true,
            wallets: [],

            reactionsLoading: true,
            reactions: [],

            merchantOrdersLoading: true,
            merchantOrders: [],

            buyerOrdersLoading: false,
            buyerOrders: [],

            devicesLoading: true,
            devices: [],

            agreementApprovalsLoading: true,
            agreementApprovals: [],

            paymentProfilesIsLoading: true,
            paymentProfiles: [],

            sessionsLoading: true,
            sessions: [],

            payoutsLoading: true,
            payouts: [],

            transactionsLoading: true,
            transactions: [],

            roleChangesLoading: true,
            roleChanges: [],

            userEventsLoading: true,
            userEvents: [],
        };

        this.roleChangesColumns = [
            {
                title: "Old role",
                render: (rowData) => (
                    <label
                        className="label"
                        style={{
                            color: "antiquewhite",
                            background: rowData.old_role_background_color,
                        }}
                    >
                        {rowData.old_role}
                    </label>
                ),
            },
            {
                title: "New role",
                render: (rowData) => (
                    <label
                        className="label"
                        style={{
                            color: "antiquewhite",
                            background: rowData.new_role_background_color,
                        }}
                    >
                        {rowData.new_role}
                    </label>
                ),
            },
            {
                title: "Event date",
                field: "created_at",
            },
        ];

        this.transactionColumns = [
            {
                title: "Title",
                field: "title",
            },
            {
                title: "Record Date",
                field: "record_date",
            },
            {
                title: "Row One",
                field: "row_one",
            },
            {
                title: "Row Two",
                field: "row_two",
            },
            {
                title: "Row Three",
                field: "row_three",
            },
            {
                title: "Resource",
                render: (rowData) => (
                    <a
                        href={`/redirect_to_resource?resource_type=${rowData.resource_type}&resource_id=${rowData.resource_id}`}
                        target="_blank"
                    >
                        {rowData.resource_type}
                    </a>
                ),
            },
        ];

        this.payoutColumns = [
            {
                title: "Payment Profile",
                field: "payment_profile",
            },
            {
                title: "Withdraw Amount",
                field: "withdraw_amount",
            },
            {
                title: "Service Fee",
                field: "service_fee",
            },
            {
                title: "Net Amount",
                field: "net_amount",
            },
            {
                title: "Net Amount",
                field: "net_amount",
            },
            {
                title: "Status",
                field: "status",
            },
            {
                title: "Progress Status",
                field: "progress_status",
            },
            {
                title: "Order ID",
                field: "order_record_id",
            },
            {
                title: "Order Status",
                field: "order_status",
            },
            {
                title: "Created at",
                field: "created_at",
            },
        ];

        this.orderReactionColumns = [
            {
                title: "Reaction",
                field: "action_type",
            },
            {
                title: "Reaction Result",
                field: "reaction_result",
            },
            {
                title: "Order",
                render: (rowData) => (
                    <a href={`/order_pools/${rowData.order_pool_id}`} target="_blank">
                        {rowData.order_record_id}
                    </a>
                ),
            },
            {
                title: "Order Status",
                render: (rowData) => {
                    if (rowData.reaction_result === "Won") {
                        return rowData.pool_status;
                    }
                },
            },
            {
                title: "Date/Time",
                field: "created_at",
            },
        ];

        this.walletColumns = [
            {
                title: this.translations.walletColumns.ffpSystemName,
                field: "ffp_system_name",
            },
            {
                title: "Status",
                field: "status",
            },
            {
                title: this.translations.walletColumns.amount,
                field: "amount",
                type: "numeric",
            },
            {
                title: this.translations.walletColumns.availableAmount,
                field: "available_amount",
                type: "numeric",
            },
            {
                title: this.translations.walletColumns.pricePerMile,
                field: "price_per_mile",
                type: "numeric",
            },
            {
                title: this.translations.walletColumns.lowestPossibleMileRequest,
                field: "minimum_order_amount",
                type: "numeric",
            },
            {
                title: this.translations.walletColumns.createdAt,
                field: "created_at",
                type: "datetime",
            },
            {
                field: "details_url",
                title: " ",
                render: (rowData) => (
                    <a
                        href={rowData.details_url}
                        className="btn btn-default btn-sm"
                        target="_blank"
                    >
                        {this.translations.detailsButton}
                    </a>
                ),
            },
        ];
        this.orderColumns = [
            {
                title: this.translations.orderColumns.ffpSystemName,
                field: "ffp_system_name",
            },
            {
                title: "Status",
                field: "status",
            },
            {
                title: this.translations.orderColumns.amount,
                field: "amount",
                type: "numeric",
            },
            {
                title: this.translations.orderColumns.paymentAmount,
                field: "payment_amount",
                type: "numeric",
            },
            {
                title: this.translations.orderColumns.pointsAmount,
                field: "points_amount",
                type: "numeric",
            },
            {
                title: this.translations.orderColumns.serviceFee,
                field: "service_fee",
                type: "numeric",
            },
            {
                title: this.translations.orderColumns.tax,
                field: "tax",
                type: "numeric",
            },
            {
                title: this.translations.orderColumns.createdAt,
                field: "created_at",
                type: "datetime",
            },
            {
                field: "details_url",
                title: " ",
                render: (rowData) => (
                    <a
                        href={rowData.details_url}
                        className="btn btn-default btn-sm"
                        target="_blank"
                    >
                        {rowData.order_record_id}
                    </a>
                ),
            },
        ];
        this.agreementColumns = [
            {
                title: this.translations.agreementColumns.event,
                field: "event_name",
            },
            {
                title: this.translations.agreementColumns.agreement,
                field: "agreement_name",
            },
            {
                title: this.translations.agreementColumns.agreementVersion,
                field: "agreement_version_name",
            },
            {
                title: this.translations.agreementColumns.ipAddress,
                field: "ip_address",
            },
            {
                title: this.translations.walletColumns.createdAt,
                field: "created_at",
                type: "datetime",
            },
            {
                field: "details_url",
                title: " ",
                render: (rowData) => (
                    <a
                        href={rowData.details_url}
                        className="btn btn-default btn-sm"
                        target="_blank"
                    >
                        {this.translations.detailsButton}
                    </a>
                ),
            },
        ];
        this.deviceColumns = [
            {
                title: this.translations.deviceColumns.name,
                field: "name",
            },
            {
                title: this.translations.deviceColumns.platform,
                field: "platform",
            },
            {
                title: this.translations.deviceColumns.osVersion,
                field: "os_version",
            },
            {
                title: this.translations.deviceColumns.appVersion,
                field: "app_version",
            },
            {
                title: this.translations.deviceColumns.status,
                field: "status",
            },
            {
                title: this.translations.deviceColumns.createdAt,
                field: "created_at",
                type: "datetime",
            },
        ];
        this.paymentProfileColumns = [
            {
                title: this.translations.paymentProfileColumns.name,
                field: "name",
            },
            {
                title:
                    this.translations.paymentProfileColumns.merchantPaymentCategoryName,
                field: "merchant_payment_category_name",
            },
            {
                title:
                    this.translations.paymentProfileColumns.merchantPaymentAccountType,
                field: "merchant_payment_account_type",
            },
            {
                title: this.translations.paymentProfileColumns.displayValue,
                field: "display_value",
            },
            {
                title: this.translations.paymentProfileColumns.approvalStatus,
                render: (rowData) => {
                    return rowData.deleted_at ? "Deleted" : rowData.approval_status;
                },
            },
            {
                title: this.translations.paymentProfileColumns.authorizationStatus,
                render: (rowData) => {
                    return rowData.deleted_at ? "Deleted" : rowData.authorization_status;
                },
            },
            {
                title: this.translations.paymentProfileColumns.createdAt,
                field: "created_at",
            },
            {
                field: "details_url",
                title: " ",
                render: (rowData) => (
                    <a
                        href={rowData.details_url}
                        className="btn btn-default btn-sm"
                        target="_blank"
                    >
                        {this.translations.detailsButton}
                    </a>
                ),
            },
        ];

        this.userEventColumns = [
            {
                title: "Progress",
                field: "",
                render: (rowData) => {
                    return (
                        <>
                            <img
                                src={require("../images/user_events/" + rowData.generic_logo)}
                                width="20px"
                            />
                            &nbsp;
                            {rowData.event_step_pretty_name}
                            &nbsp; - &nbsp;
                            {rowData.step_index}
                        </>
                    );
                },
            },
            {
                title: "Order Date",
                field: "order_date",
            },
            {
                title: "Program",
                field: "program",
                render: (rowData) => {
                    if (typeof rowData.ffp_system_id == "number") {
                        return (
                            <a href={"/ffp_systems/" + rowData.ffp_system_id} target="_blank">
                                {" "}
                                {rowData.program_name}
                            </a>
                        );
                    } else {
                        return <></>;
                    }
                },
            },
            {
                title: "Content",
                field: "content",
            },
            {
                title: "Original Price",
                field: "original_price",
            },
            {
                title: "PS Price",
                field: "ps_price",
            },
            {
                title: "Last Update",
                field: "last_update",
                render: (rowData) => {
                    return (
                        <a
                            href={`/user_events/${rowData.id}`}
                            className="btn btn-default"
                            target="_blank"
                        >
                            {rowData.last_update}
                        </a>
                    );
                },
            },
        ];

        this.sessionColumns = [
            {
                title: "Session",
                field: "session",
            },
            {
                title: "Program",
                field: "program",
            },
            {
                title: "Delivery Option",
                field: "delivery_option",
            },
            {
                title: "Miles Amount",
                field: "miles_amount",
            },
            {
                title: "Tax/Program Fee",
                field: "tax_program_fee",
            },
            {
                title: "P. Amount",
                field: "payment_amount",
            },
            {
                title: "From",
                field: "from",
            },
            {
                title: "To",
                field: "to",
            },
            {
                title: "Payment",
                field: "payment",
            },
            {
                title: "Date/Time",
                field: " ",
                render: (rowData) => (
                    <a
                        href={rowData.details_url}
                        className="btn btn-default btn-sm"
                        target="_blank"
                    >
                        {rowData.date_time}
                    </a>
                ),
            },
        ];
    }

    componentDidMount() {
        fetch(`/user_related_resources/wallets?user_id=${this.props.userId}`)
            .then((response) => response.json())
            .then((data) => {
                this.setState({ wallets: data, walletsLoading: false });
            });

        fetch(
            `/user_related_resources/merchant_orders?user_id=${this.props.userId}`
        )
            .then((response) => response.json())
            .then((data) => {
                this.setState({ merchantOrders: data, merchantOrdersLoading: false });
            });

        fetch(`/user_related_resources/buyer_orders?user_id=${this.props.userId}`)
            .then((response) => response.json())
            .then((data) => {
                this.setState({ buyerOrders: data, buyerOrdersLoading: false });
            });

        fetch(`/user_related_resources/devices?user_id=${this.props.userId}`)
            .then((response) => response.json())
            .then((data) => {
                this.setState({ devices: data, devicesLoading: false });
            });

        fetch(
            `/user_related_resources/agreement_approvals?user_id=${this.props.userId}`
        )
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    agreementApprovals: data,
                    agreementApprovalsLoading: false,
                });
            });

        fetch(
            `/user_related_resources/merchant_payment_profiles?user_id=${this.props.userId}`
        )
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    paymentProfiles: data,
                    paymentProfilesIsLoading: false,
                });
            });

        fetch(`/user_related_resources/sessions?user_id=${this.props.userId}`)
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    sessions: data,
                    sessionsLoading: false,
                });
            });

        fetch(`/user_related_resources/payouts?user_id=${this.props.userId}`)
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    payouts: data,
                    payoutsLoading: false,
                });
            });

        fetch(`/user_related_resources/reactions?user_id=${this.props.userId}`)
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    reactions: data,
                    reactionsLoading: false,
                });
            });

        fetch(
            `/user_related_resources/transaction_records?user_id=${this.props.userId}`
        )
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    transactions: data,
                    transactionsLoading: false,
                });
            });

        fetch(`/user_related_resources/user_events?user_id=${this.props.userId}`)
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    userEvents: data,
                    userEventsLoading: false,
                });
            });

        fetch(
            `/user_related_resources/customer_role_changes?user_id=${this.props.userId}`
        )
            .then((response) => response.json())
            .then((data) => {
                this.setState({
                    roleChanges: data,
                    roleChangesLoading: false,
                });
            });
    }

    render() {
        return (
            <Tabs>
                <TabList>
                    <Tab>{this.translations.tabHeaders.wallets}</Tab>
                    <Tab>Order Reactions</Tab>
                    <Tab>{this.translations.tabHeaders.merchantOrders}</Tab>
                    <Tab>{this.translations.tabHeaders.buyerOrders}</Tab>
                    <Tab>{this.translations.tabHeaders.devices}</Tab>
                    <Tab>{this.translations.tabHeaders.agreements}</Tab>
                    <Tab>{this.translations.tabHeaders.merchantPaymentProfiles}</Tab>
                    <Tab>Sessions</Tab>
                    <Tab>User Events</Tab>
                    <Tab>Payouts</Tab>
                    <Tab>Transaction History</Tab>
                    <Tab>Role Changes</Tab>
                </TabList>

                <TabPanel>
                    {/* My wallets table */}
                    <MaterialTable
                        icons={tableIcons}
                        isLoading={this.state.walletsLoading}
                        columns={this.walletColumns}
                        data={this.state.wallets}
                        title=" "
                        options={commonStyles}
                        localization={{
                            pagination: this.materialTableTranslations.pagination,
                            toolbar: this.materialTableTranslations.toolbar,
                            header: this.materialTableTranslations.header,
                            body: this.materialTableTranslations.body,
                            grouping: this.materialTableTranslations.grouping,
                        }}
                    />
                </TabPanel>

                <TabPanel>
                    {/* Order reactions table */}
                    <MaterialTable
                        icons={tableIcons}
                        isLoading={this.state.reactionsLoading}
                        columns={this.orderReactionColumns}
                        data={this.state.reactions}
                        title=" "
                        options={commonStyles}
                        localization={{
                            pagination: this.materialTableTranslations.pagination,
                            toolbar: this.materialTableTranslations.toolbar,
                            header: this.materialTableTranslations.header,
                            body: this.materialTableTranslations.body,
                            grouping: this.materialTableTranslations.grouping,
                        }}
                    />
                </TabPanel>

                <TabPanel>
                    {/* Orders created for me table */}
                    <MaterialTable
                        icons={tableIcons}
                        isLoading={this.state.merchantOrdersLoading}
                        columns={this.orderColumns}
                        data={this.state.merchantOrders}
                        title=" "
                        options={commonStyles}
                        localization={{
                            pagination: this.materialTableTranslations.pagination,
                            toolbar: this.materialTableTranslations.toolbar,
                            header: this.materialTableTranslations.header,
                            body: this.materialTableTranslations.body,
                            grouping: this.materialTableTranslations.grouping,
                        }}
                    />
                </TabPanel>

                <TabPanel>
                    {/* Orders that I create table */}
                    <MaterialTable
                        icons={tableIcons}
                        isLoading={this.state.buyerOrdersLoading}
                        columns={this.orderColumns}
                        data={this.state.buyerOrders}
                        title=" "
                        options={commonStyles}
                        localization={{
                            pagination: this.materialTableTranslations.pagination,
                            toolbar: this.materialTableTranslations.toolbar,
                            header: this.materialTableTranslations.header,
                            body: this.materialTableTranslations.body,
                            grouping: this.materialTableTranslations.grouping,
                        }}
                    />
                </TabPanel>

                <TabPanel>
                    {/* Devices table */}
                    <MaterialTable
                        icons={tableIcons}
                        isLoading={this.state.devicesLoading}
                        columns={this.deviceColumns}
                        data={this.state.devices}
                        title=" "
                        options={commonStyles}
                        localization={{
                            pagination: this.materialTableTranslations.pagination,
                            toolbar: this.materialTableTranslations.toolbar,
                            header: this.materialTableTranslations.header,
                            body: this.materialTableTranslations.body,
                            grouping: this.materialTableTranslations.grouping,
                        }}
                    />
                </TabPanel>

                <TabPanel>
                    {/* Agreement Approvals table */}
                    <MaterialTable
                        icons={tableIcons}
                        isLoading={this.state.agreementApprovalsLoading}
                        columns={this.agreementColumns}
                        data={this.state.agreementApprovals}
                        title=" "
                        options={commonStyles}
                        localization={{
                            pagination: this.materialTableTranslations.pagination,
                            toolbar: this.materialTableTranslations.toolbar,
                            header: this.materialTableTranslations.header,
                            body: this.materialTableTranslations.body,
                            grouping: this.materialTableTranslations.grouping,
                        }}
                    />
                </TabPanel>

                <TabPanel>
                    {/* Merchant Payment Profiles table */}
                    <MaterialTable
                        icons={tableIcons}
                        isLoading={this.state.paymentProfilesIsLoading}
                        columns={this.paymentProfileColumns}
                        data={this.state.paymentProfiles}
                        title=" "
                        options={commonStyles}
                        localization={{
                            pagination: this.materialTableTranslations.pagination,
                            toolbar: this.materialTableTranslations.toolbar,
                            header: this.materialTableTranslations.header,
                            body: this.materialTableTranslations.body,
                            grouping: this.materialTableTranslations.grouping,
                        }}
                    />
                </TabPanel>

                <TabPanel>
                    <MaterialTable
                        icons={tableIcons}
                        isLoading={this.state.sessionsLoading}
                        columns={this.sessionColumns}
                        data={this.state.sessions}
                        title=" "
                        options={commonStyles}
                        localization={{
                            pagination: this.materialTableTranslations.pagination,
                            toolbar: this.materialTableTranslations.toolbar,
                            header: this.materialTableTranslations.header,
                            body: this.materialTableTranslations.body,
                            grouping: this.materialTableTranslations.grouping,
                        }}
                    />
                </TabPanel>

                <TabPanel>
                    <MaterialTable
                        icons={tableIcons}
                        isLoading={this.state.userEventsLoading}
                        columns={this.userEventColumns}
                        data={this.state.userEvents}
                        title=" "
                        options={commonStyles}
                        localization={{
                            pagination: this.materialTableTranslations.pagination,
                            toolbar: this.materialTableTranslations.toolbar,
                            header: this.materialTableTranslations.header,
                            body: this.materialTableTranslations.body,
                            grouping: this.materialTableTranslations.grouping,
                        }}
                    />
                </TabPanel>

                <TabPanel>
                    <MaterialTable
                        icons={tableIcons}
                        isLoading={this.state.payoutsLoading}
                        columns={this.payoutColumns}
                        data={this.state.payouts}
                        title=" "
                        options={commonStyles}
                        localization={{
                            pagination: this.materialTableTranslations.pagination,
                            toolbar: this.materialTableTranslations.toolbar,
                            header: this.materialTableTranslations.header,
                            body: this.materialTableTranslations.body,
                            grouping: this.materialTableTranslations.grouping,
                        }}
                    />
                </TabPanel>

                <TabPanel>
                    <MaterialTable
                        icons={tableIcons}
                        isLoading={this.state.transactionsLoading}
                        columns={this.transactionColumns}
                        data={this.state.transactions}
                        title=" "
                        options={commonStyles}
                        localization={{
                            pagination: this.materialTableTranslations.pagination,
                            toolbar: this.materialTableTranslations.toolbar,
                            header: this.materialTableTranslations.header,
                            body: this.materialTableTranslations.body,
                            grouping: this.materialTableTranslations.grouping,
                        }}
                    />
                </TabPanel>

                <TabPanel>
                    <MaterialTable
                        icons={tableIcons}
                        isLoading={this.state.roleChangesLoading}
                        columns={this.roleChangesColumns}
                        data={this.state.roleChanges}
                        title=" "
                        options={commonStyles}
                        localization={{
                            pagination: this.materialTableTranslations.pagination,
                            toolbar: this.materialTableTranslations.toolbar,
                            header: this.materialTableTranslations.header,
                            body: this.materialTableTranslations.body,
                            grouping: this.materialTableTranslations.grouping,
                        }}
                    />
                </TabPanel>
            </Tabs>
        );
    }
}

export default ProfileRelatedResources;
