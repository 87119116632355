import { Controller } from "@hotwired/stimulus";

// data-order-pool-revisions-target
// Connects to data-controller="order-pool-revisions"
export default class extends Controller {
  static targets = [
    "requiredMiles",
    "priceMiles",
    "tax",
    "serviceFee",
    "totalPrice",
    "priceStabilization",
    "promoUsage",
    "priceMode",
  ];

  connect() {
    this.requiredMilesTarget.readOnly = true;
    this.priceMilesTarget.readOnly = true;
    this.taxTarget.readOnly = true;
    this.serviceFeeTarget.readOnly = true;
    this.totalPriceTarget.readOnly = true;
    this.priceStabilizationTarget.readOnly = true;
    this.promoUsageTarget.readOnly = true;

    this.priceModeTarget.value = this.initialpricemodeValue;
  }

  static values = {
    url: String,
    promopresent: Boolean,
    initialpricemode: String,
  };

  disableInputFields() {
    this.requiredMilesTarget.readOnly = true;
    this.priceMilesTarget.readOnly = true;
    this.taxTarget.readOnly = true;
    this.serviceFeeTarget.readOnly = true;
    this.totalPriceTarget.readOnly = true;
    this.priceStabilizationTarget.readOnly = true;
    this.promoUsageTarget.readOnly = true;
  }

  handleManual() {
    this.priceModeTarget.value = "manual";
    this.requiredMilesTarget.readOnly = false;
    this.priceMilesTarget.readOnly = false;
    this.taxTarget.readOnly = false;
    this.serviceFeeTarget.readOnly = false;
    this.totalPriceTarget.readOnly = false;
    this.priceStabilizationTarget.readOnly = false;
    if (this.promopresentValue) this.promoUsageTarget.readOnly = false;
  }

  async handleRegular(e) {
    e.target.disabled = true;
    this.disableInputFields();
    this.priceModeTarget.value = "regular";

    try {
      const req = await fetch(`${this.urlValue}/calculate_for_regular`);
      const resp = await req.json();

      this.requiredMilesTarget.value = resp.amount;
      this.priceMilesTarget.value = resp.points_amount;
      this.taxTarget.value = resp.tax;
      this.serviceFeeTarget.value = resp.service_fee;
      this.totalPriceTarget.value = resp.payment_amount;
      this.priceStabilizationTarget.value = resp.price_stabilization;
      this.promoUsageTarget.value = resp.promo_usage;
    } catch (err) {
    } finally {
      e.target.disabled = false;
    }
  }

  async handlePromo(e) {
    e.target.disabled = true;

    this.disableInputFields();
    this.priceModeTarget.value = "promotion";

    try {
      const req = await fetch(`${this.urlValue}/calculate_for_promotion`);
      const resp = await req.json();

      this.requiredMilesTarget.value = resp.amount;
      this.priceMilesTarget.value = resp.points_amount;
      this.taxTarget.value = resp.tax;
      this.serviceFeeTarget.value = resp.service_fee;
      this.totalPriceTarget.value = resp.payment_amount;
      this.priceStabilizationTarget.value = resp.price_stabilization;
      this.promoUsageTarget.value = resp.promo_usage;
    } catch (err) {
    } finally {
      e.target.disabled = false;
    }
  }
}
