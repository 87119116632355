import React, { useState } from "react";
import _ from "lodash";
import currency from "currency.js";
import ShowErrors from "./ShowErrors";

const TableItemDisplay = ({item, setSelectedState}) => {
    return <tr className="active">
        <td>#{item.id}</td>
        <td>{item.from || "Any"}</td>
        <td>{item.to || "Any"}</td>
        <td>{item.date_start || "Any"}</td>
        <td>{item.date_end || "Any"}</td>
        <td>{_.capitalize(item.flight_class || "any")}</td>
        <td>{item.miles_start || "Any"}</td>
        <td>{item.miles_end || "Any"}</td>
        <td>{currency(item.price_per_mile).format()}</td>
        <td>{item.service_fee}%</td>
        <td>
            <button className="btn btn-warning btn-sm"
                    onClick={() => setSelectedState("form")}>Edit</button>
        </td>
    </tr>
}

const TableItemForm = ({item, setSelectedState, token, setDefaultRange}) => {
    const [errors, setErrors] = useState(null)

    const [pricePerMile, setPricePerMile] = useState(item.price_per_mile)
    const [serviceFee, setServiceFee] = useState(10)

    const [rowClass, setRowClass] = useState("active")

    const handleSubmit = () => {
        const data = {
            flight_price_range: {
                price_per_mile: pricePerMile,
                service_fee: serviceFee
            }
        }

        fetch(`/flight_price_ranges/${item.id}`, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': token
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.errors) {
                    setRowClass("danger")

                    setTimeout(() => { setRowClass("active") }, 1000)

                    setErrors(data.errors)
                } else {
                    setRowClass("success")

                    setTimeout(() => {
                        setRowClass("active")
                        setSelectedState("display")
                        setDefaultRange(data)
                    }, 1000)

                    setErrors(null)
                }
            })
    }

    return <>
        <ShowErrors errors={errors} />

        <tr className={rowClass}>
            <td>#{item.id}</td>

            <td>
                Any
            </td>

            <td>
                Any
            </td>

            <td>
                Any
            </td>

            <td>
                Any
            </td>

            <td>
                Any
            </td>

            <td>
                Any
            </td>

            <td>
                Any
            </td>

            <td>
                <div className="input-group">
                    <span className="input-group-addon">$</span>
                    <input className="form-control" id="input_group_a" aria-describedby="input_group_a"
                           type="number" step={0.01}
                           value={pricePerMile || 11.0}
                           onChange={(elem) => {setPricePerMile(elem.target.value)}} />
                </div>
            </td>

            <td>
                <div className="input-group">
                    <input className="form-control" id="input_group_a" aria-describedby="input_group_abcd"
                           type="number" step={1}
                           value={serviceFee || 10}
                           onChange={(elem) => {setServiceFee(elem.target.value)}} />
                    <span className="input-group-addon">%</span>
                </div>
            </td>

            <td>
                <div className="btn-group" role="group">
                    <button className="btn btn-success btn-sm" onClick={handleSubmit}>Save</button>
                    <button className="btn btn-danger btn-sm" onClick={() => setSelectedState("display")}>Cancel</button>
                </div>
            </td>
        </tr>
    </>
}

const DefaultRange = ({ token, default_range }) => {
    const [defaultRange, setDefaultRange] = useState(default_range)

    const [selectedState, setSelectedState] = useState("display")

    if (selectedState === "display") {
        return <TableItemDisplay item={defaultRange} setSelectedState={setSelectedState} />
    } else {
        return <TableItemForm setDefaultRange={setDefaultRange} token={token} item={defaultRange} setSelectedState={setSelectedState} />
    }
}

export default DefaultRange
