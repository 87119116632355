import React from "react";
import ApplicableWalletTableRow from "./ApplicableWalletTableRow";
import { ReassignProvider } from "./ReassignOrderState";

const ApplicableWallets = (props) => {
    const wallets = props.wallets;
    const availableOptions = props.available_options;

    return <div className="panel panel-default">
        <div className="panel-heading">
            <h3 className="panel-title">
                {props.translations.titles.applicable_wallets}
            </h3>
        </div>

        <div className="panel-body">
            <table className="table">
                <thead>
                    <tr>
                        <th>
                            {props.translations.table_headers.user}
                        </th>

                        <th>
                            {props.translations.table_headers.minimum_order}
                        </th>

                        <th>
                            {props.translations.table_headers.available_miles}
                        </th>

                        <th>
                            {props.translations.table_headers.price_per_mile}
                        </th>

                        <th>
                            {props.translations.table_headers.merchant_fee}
                        </th>

                        <th>
                            {props.translations.table_headers.tax}
                        </th>

                        <th>
                            {props.translations.table_headers.commission}
                        </th>

                        <th>
                            {props.translations.table_headers.total_price}
                        </th>

                        <th>
                            {props.translations.table_headers.diff}
                        </th>

                        <th>
                            Deadline
                        </th>

                        <th>
                            &nbsp;
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <ReassignProvider
                        authenticityToken={props.authenticityToken}
                    >
                        {wallets.map(wallet => {
                            return <ApplicableWalletTableRow
                                orderId={props.order_id}
                                tax={props.tax}
                                commission={props.commission}
                                wallet={wallet}
                                key={wallet.id}
                                amount={props.amount}
                                payment_amount={props.payment_amount}
                                buttonText={props.translations.buttons.assign_to_wallet}
                                confirmText={props.translations.confirm_messages.confirm_reassign}
                                availableOptions={availableOptions}
                            />
                        })}
                    </ReassignProvider>
                </tbody>
            </table>
        </div>
    </div>;
};

export default ApplicableWallets;
