import React from "react";
import AsyncSelect from 'react-select/async';

class FfpSystemSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultOptions: [],
            isLoading: false,
            selectedId: props.selectedId,
            selectedName: props.selectedName,
            selectedOption: ''
        }
    }

    fetchFfpSystemsAsync(inputValue, callback) {
        fetch(`/ffp_systems/filtered_by_name.json?query=${inputValue}`)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    isLoading: false
                });

                callback(data);
            });
    }

    render() {
        return <>
            <AsyncSelect
                isClearable={true}
                loadOptions={this.fetchFfpSystemsAsync.bind(this)}
                defaultOptions={this.state.defaultOptions}
                name="ffp_system_id"
                onChange={this.handleChange}
                isLoading={this.state.isLoading}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                defaultValue={this.state.selectedId ? {
                    id: this.state.selectedId,
                    name: this.state.selectedName
                } : undefined}
            />
        </>;
    }

}

export default FfpSystemSelector;
