import React, { useState } from "react"
import queryString from "query-string"

const UserSearchPartial = () => {
    const parsedParams = queryString.parse(document.location.search)
    const [firstName, setFirstName] = useState(parsedParams.first_name || "")
    const [lastName, setLastName] = useState(parsedParams.last_name || "")

    return <>
        <div className="col-md-1">
            <label>First Name</label>

            <input name="first_name" className="form-control" value={firstName} placeholder="First Name" onChange={ev => setFirstName(ev.target.value)} /> <br />
        </div>

        <div className="col-md-1">
            <label>Last Name</label>

            <input name="last_name" className="form-control" value={lastName} placeholder="Last Name" onChange={ev => setLastName(ev.target.value)} />
        </div>
    </>
}

export default UserSearchPartial
