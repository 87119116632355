import React from "react"

import * as _ from "lodash"

import WalletInPool from "./WalletInPool";
import ApplicableWallet from "./ApplicableWallet";
import OrderPoolLoading from "./OrderPoolLoading";
import OrderPoolPaginator from "./OrderPoolPaginator";

class OrderPoolManager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,

            filterSorting: 'price_per_mile_up',
            filterSellerName: '',

            filterMinDelta: -5000,
            filterMaxDelta: 5000,

            filterMilePriceMin: 0,
            filterMilePriceMax: 50,

            filterAvailableAmountMin: 500,
            filterAvailableAmountMax: 1000000000,

            pageSize: 25,
            currentPage: 1,

            milesCount: props.miles_count,
            tax: props.tax,
            paymentAmount: props.payment_amount,
            serviceFee: props.service_fee,

            applicableWallets: props.availableWallets,
            walletsInPool: props.currentWallets,

            // applicableWallets: [
            //     {
            //         id: 1,
            //         seller: "Volkan Süner",
            //         availableAmount: 20000,
            //         priceForMiles: 7,
            //         orderPrice: 500.59,
            //         delta: 7,
            //     },
            //     {
            //         id: 3,
            //         seller: "Egemen Çakmak",
            //         availableAmount: 15000,
            //         priceForMiles: 9.99,
            //         orderPrice: 500.59,
            //         delta: 10,
            //     },
            //     {
            //         id: 4,
            //         seller: "Sertan Gülveren",
            //         availableAmount: 100000,
            //         priceForMiles: 12.5,
            //         orderPrice: 500.59,
            //         delta: 13,
            //     },
            //     {
            //         id: 5,
            //         seller: "Yağmur Ünal",
            //         availableAmount: 150000,
            //         priceForMiles: 16.5,
            //         orderPrice: 600.59,
            //         delta: -5,
            //     },
            //     {
            //         id: 6,
            //         seller: "Yigidis Sadicis",
            //         availableAmount: 110000,
            //         priceForMiles: 11.5,
            //         orderPrice: 400.59,
            //         delta: 5,
            //     },
            // ],
            // walletsInPool: [{
            //     id: 2,
            //     seller: "Kaan Sezyum",
            //     priceForMiles: 8.59,
            //     delta: 3,
            //     orderPrice: 500.59,
            //     responseTime: 6,
            //     sellerIncome: 200.34,
            //     totalServiceFee: 150.2
            // }],

            filteredWallets: []
        }
    }

    componentDidMount() {
        this.setState({
            filteredWallets: this.state.applicableWallets,
            totalPages: Math.ceil(this.state.applicableWallets.length / this.state.pageSize)
        })
    }

    addToPool(itemToAdd) {
        let a = [...this.state.applicableWallets]
        let b = [...this.state.filteredWallets]
        let c = [itemToAdd, ...this.state.walletsInPool]

        a = a.filter(item => {
            return item.id !== itemToAdd.id
        })

        b = b.filter(item => {
            return item.id !== itemToAdd.id
        })

        this.setState({
            applicableWallets: a,
            filteredWallets: b,
            walletsInPool: c
        })
    }

    changeLoading(value) {
        this.setState({isLoading: value})
    }

    handleFilterAndSort() {
        let w = [...this.state.applicableWallets]

        if (this.state.filterSellerName) {
            w = w.filter(item => {
                return item.seller.toLowerCase().includes(this.state.filterSellerName.toLowerCase())
            })
        }

        w = w.filter(item => {
            return item.delta >= this.state.filterMinDelta && item.delta <= this.state.filterMaxDelta
        })

        w = w.filter(item => {
            return item.priceForMiles >= this.state.filterMilePriceMin && item.priceForMiles <= this.state.filterMilePriceMax
        })

        w = w.filter(item => {
            return item.availableAmount >= this.state.filterAvailableAmountMin && item.availableAmount <= this.state.filterAvailableAmountMax
        })

        this.setState({
            filteredWallets: w,
            totalPages: Math.ceil(w.length / this.state.pageSize)
        })
    }

    handleResetFilter() {
        this.setState({
            filterSorting: 'price_per_mile_up',
            filterSellerName: '',
            filterMinDelta: -5000,
            filterMaxDelta: 5000,
            filterMilePriceMin: 0,
            filterMilePriceMax: 50,
            filterAvailableAmountMin: 500,
            filterAvailableAmountMax: 1000000000,

            currentPage: 1,
            totalPages: Math.ceil(this.state.applicableWallets.length / this.state.pageSize),

            filteredWallets: this.state.applicableWallets
        })
    }

    render() {
        let itemsToDisplay = _.chunk(this.state.filteredWallets, this.state.pageSize)[this.state.currentPage - 1]

        return <div>
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-2">
                            Seller <br/>

                            <input placeholder="Filter by seller"
                                   className="form-control"
                                   value={this.state.filterSellerName}
                                   onChange={(e) => this.setState({filterSellerName: e.target.value})}
                            />
                        </div>

                        <div className="col-md-2">
                            <div className="row">
                                <div className="col-md-12">
                                    Miles Amount
                                </div>

                                <div className="col-md-12">
                                    <input placeholder="Minimum available amount"
                                           className="form-control"
                                           value={this.state.filterAvailableAmountMin}
                                           onChange={(e) => this.setState({filterAvailableAmountMin: parseFloat(e.target.value)})}
                                           type="number"
                                           step={1}
                                           min={0}
                                           max={5000000}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <input placeholder="Maximum available amount"
                                           className="form-control"
                                           value={this.state.filterAvailableAmountMax}
                                           onChange={(e) => this.setState({filterAvailableAmountMax: parseFloat(e.target.value)})}
                                           type="number"
                                           step={1}
                                           min={0}
                                           max={5000000}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="row">
                                <div className="col-md-12">
                                    Mile Price
                                </div>

                                <div className="col-md-12">
                                    <input placeholder="Minimum mile price"
                                           className="form-control"
                                           value={this.state.filterMilePriceMin}
                                           onChange={(e) => this.setState({filterMilePriceMin: parseFloat(e.target.value)})}
                                           type="number"
                                           step={0.01}
                                           min={0}
                                           max={500000}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <input placeholder="Maximum mile price"
                                           className="form-control"
                                           value={this.state.filterMilePriceMax}
                                           onChange={(e) => this.setState({filterMilePriceMax: parseFloat(e.target.value)})}
                                           type="number"
                                           step={0.01}
                                           min={1}
                                           max={500000}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="row">
                                <div className="col-md-12">
                                    Delta
                                </div>

                                <div className="col-md-12">
                                    <input placeholder="Minimum delta"
                                           className="form-control"
                                           value={this.state.filterMinDelta}
                                           onChange={(e) => {
                                               this.setState({
                                                   filterMinDelta: parseFloat(e.target.value)
                                               })
                                           }}
                                           type="number"
                                           step={0.01}
                                           min={-5000}
                                           max={5000}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <input placeholder="Maximum delta"
                                           className="form-control"
                                           value={this.state.filterMaxDelta}
                                           onChange={(e) => {
                                               this.setState({
                                                   filterMaxDelta: parseFloat(e.target.value)
                                               })
                                           }}
                                           type="number"
                                           step={0.01}
                                           min={-5000}
                                           max={5000}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="col-md-12">
                                Sorting
                                <select className="form-control"
                                        value={this.state.filterSorting}
                                        onChange={(e) => {
                                            let w = [...this.state.filteredWallets]
                                            w = w.map(item => {
                                                let i = item

                                                i.priceForMiles = parseFloat(i.priceForMiles)
                                                i.availableAmount = parseFloat(i.availableAmount)
                                                i.delta = parseFloat(i.delta)

                                                return i
                                            })

                                            switch (e.target.value) {
                                                case "price_per_mile_up":
                                                    w = _.orderBy(w, 'priceForMiles', 'asc')

                                                    break;
                                                case "price_per_mile_down":
                                                    w = _.orderBy(w, 'priceForMiles', 'desc')

                                                    break;
                                                case "amount_up":
                                                    w = _.orderBy(w, 'availableAmount', 'asc')

                                                    break;
                                                case "amount_down":
                                                    w = _.orderBy(w, 'availableAmount', 'desc')

                                                    break;
                                                case "delta_up":
                                                    w = _.orderBy(w, 'delta', 'asc')

                                                    break;
                                                case "delta_down":
                                                    w = _.orderBy(w, 'delta', 'desc')

                                                    break;
                                                case "updated_at_up":
                                                    w = _.orderBy(w, "updatedAt", "asc")
                                                    break;
                                                case "updated_at_down":
                                                    w = _.orderBy(w, "updatedAt", "desc")
                                                    break;
                                                case "application_count_up":
                                                    w = _.orderBy(w, "applicationCount", "asc")
                                                    break;
                                                case "application_count_down":
                                                    w = _.orderBy(w, "applicationCount", "desc")
                                                    break;
                                                default:
                                                    w = _.orderBy(w, 'priceForMiles', 'asc')

                                                    break;
                                            }

                                            this.setState({
                                                filteredWallets: w,
                                                filterSorting: e.target.value,
                                            })
                                        }}>
                                    <option value="price_per_mile_up">Price per mile ⬆️</option>
                                    <option value="price_per_mile_down">Price per mile ⬇️️</option>

                                    <option value="amount_up">Available amount ⬆️</option>
                                    <option value="amount_down">Available amount ⬇️️</option>

                                    <option value="delta_up">Delta ⬆️</option>
                                    <option value="delta_down">Delta ⬇️️</option>

                                    <option value="updated_at_up">Last update ⬆️</option>
                                    <option value="updated_at_down">Last update ⬇️️</option>

                                    <option value="application_count_up">Application count ⬆️</option>
                                    <option value="application_count_down">Application count ⬇️️</option>
                                </select>
                            </div>

                            <OrderPoolPaginator
                                filteredLength={this.state.filteredWallets.length}
                                currentPage={this.state.currentPage}
                                totalPages={this.state.totalPages}

                                handlePrevious={() =>
                                    this.setState({
                                        currentPage: this.state.currentPage - 1
                                    })
                                }

                                handleNext={() =>
                                    this.setState({
                                        currentPage: this.state.currentPage + 1
                                    })
                                } />
                        </div>

                        <div className="col-md-1 col-md-offset-1">
                            <div>
                                <button className="btn btn-primary" onClick={() => this.handleFilterAndSort()}>
                                    Filter
                                </button>
                            </div>

                            <div>
                                <button className="btn btn-info" onClick={() => this.handleResetFilter()}>
                                    Reset Filter
                                </button>
                            </div>
                        </div>
                    </div>

                    <table className="table">
                        <thead>
                        <tr>
                            <th>Seller</th>
                            <th>Application Count</th>
                            <th>Last Update</th>
                            <th>Available Amount</th>
                            <th>Price for 1K Miles</th>
                            <th>Seller Income</th>
                            <th>Total Service Fee</th>
                            <th>Delta</th>
                            <th>Response Time</th>
                            <th>&nbsp;</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr className="success">
                            <td className="text-center" colSpan={10}><h5>Current wallets in pool</h5></td>
                        </tr>

                        {this.state.isLoading ? <OrderPoolLoading/> : this.state.walletsInPool.map(item => <WalletInPool
                            key={item.id} item={item}/>)}

                        <tr className="warning">
                            <td className="text-center" colSpan={10}><h5>Applicable Wallets</h5></td>
                        </tr>

                        {
                            itemsToDisplay &&
                            itemsToDisplay.map(item => <ApplicableWallet
                                key={item.id}
                                item={item}
                                orderPoolId={this.props.orderPoolId}
                                addToPool={this.addToPool.bind(this)}
                                changeLoading={this.changeLoading.bind(this)}
                                authenticityToken={this.props.authenticityToken}
                                tax={this.state.tax}
                                milesCount={this.state.milesCount}
                                paymentAmount={this.state.paymentAmount}
                                serviceFee={this.state.serviceFee}
                            />)}
                        </tbody>
                    </table>

                    <div className="row">
                        <div className="col-md-2 col-md-offset-5">
                            <OrderPoolPaginator
                                filteredLength={this.state.filteredWallets.length}
                                currentPage={this.state.currentPage}
                                totalPages={this.state.totalPages}

                                handlePrevious={() =>
                                    this.setState({
                                        currentPage: this.state.currentPage - 1
                                    })
                                }

                                handleNext={() =>
                                    this.setState({
                                        currentPage: this.state.currentPage + 1
                                    })
                                } />
                        </div>
                    </div>
                </div>

            </div>
        </div>;
    }
}

export default OrderPoolManager
