import React, {useEffect, useState} from "react";

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

const RegistrationStatistics = ({ selectedRange }) => {
    const colText = `col-md-${selectedRange === "daily" ? "4" : "6"}`;
    const [loading, setLoading] = useState(true);
    const [displayValue, setDisplayValue] = useState("");
    const [displayData, setDisplayData] = useState([]);

    useEffect(() => {
        fetch(`/dashboard/registration_statistics?selected_range=${selectedRange}`)
            .then(response => response.json())
            .then(data => {
                setLoading(false);

                if (selectedRange === "daily") {
                    setDisplayValue(data[0].uv);
                } else {
                    setDisplayData(data);
                }
            });
    }, [selectedRange]);

    if (loading) {
        return "Loading..."
    }

    const renderLineChart = (
        <LineChart width={600} height={300} data={displayData}>
            <Line type="monotone" dataKey="uv" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="name" />
            <Tooltip />
            <YAxis />
        </LineChart>
    );

    return <>
        <div className={colText}>
            <h2>
                Registration Statistics
            </h2>

            {selectedRange === "daily" ? <h3>{displayValue}</h3> : renderLineChart}
        </div>
    </>
};

export default RegistrationStatistics;
