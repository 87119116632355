import React from "react"

const OrderPoolPaginator = ({ filteredLength, currentPage, totalPages, handlePrevious, handleNext }) => {
    if (totalPages === 1) return '';

    return <>
        <div className="col-md-12">
            Total {filteredLength} wallets
            in {totalPages} pages. <br/>

            <div className="row">
                <div className="col-md-4">
                    <button
                        className="btn btn-primary"
                        disabled={currentPage === 1}
                        onClick={handlePrevious}
                    >
                        Prev
                    </button>
                </div>

                <div className="col-md-4">
                    {currentPage} / {totalPages}
                </div>

                <div className="col-md-4">
                    <button
                        className="btn btn-primary"
                        disabled={currentPage === totalPages}
                        onClick={handleNext}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </>
}

export default OrderPoolPaginator
