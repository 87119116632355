import React, { useState } from "react";

const FilterForm = ({ handleFilter, resetFilter }) => {
    const [id, setId] = useState("")

    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")
    const [flightClass, setFlightClass] = useState("")

    const [milesStart, setMilesStart] = useState("")
    const [milesEnd, setMilesEnd] = useState("")

    const preCallResetFilter = () => {
        setId("")
        setFrom("")
        setTo("")
        setFlightClass("")
        setMilesStart("")
        setMilesEnd("")
        setDateStart("")
        setDateEnd("")

        resetFilter()
    }

    return <>
        <tr className="info">
            <th>
                <input type="text" placeholder="ID" className="form-control"
                    value={id || ""} onChange={(elem) => setId(
                        elem.target.value)}
                />
            </th>

            <th>
                <input type="text" value={from || ""} onChange={(elem) => setFrom(
                    elem.target.value)} placeholder="From" className="form-control" style={{ textTransform: "uppercase" }} />
            </th>

            <th>
                <input type="text" value={to || ""} onChange={(elem) => setTo(
                    elem.target.value)} placeholder="To" className="form-control" style={{ textTransform: "uppercase" }} />
            </th>

            <th>
                <select value={flightClass || ""} onChange={(elem) => { setFlightClass(elem.target.value) }} className="form-control">
                    <option value="">Any</option>
                    <option value="E">Economy</option>
                    <option value="B">Business</option>
                </select>
            </th>

            <th>
                <input type="number" placeholder="Mile Start" step={1} className="form-control" value={milesStart || ""} onChange={(elem) => { setMilesStart(elem.target.value) }} />
            </th>

            <th>
                <input type="number" placeholder="Mile End" step={1} className="form-control" value={milesEnd || ""} onChange={(elem) => { setMilesEnd(elem.target.value) }} />
            </th>

            <th>
                Price Per 1k
            </th>

            <th>
                Service Fee
            </th>

            <th>
                <button className="btn btn-default btn-sm"
                    onClick={preCallResetFilter}>Clear</button>

                <button className="btn btn-primary btn-sm"
                    onClick={() => {
                        handleFilter({ id, from, to, flightClass, milesStart, milesEnd })
                    }}
                >Filter</button>
            </th>
        </tr>
    </>
}

export default FilterForm
