import React, { useState } from "react";
import queryString from "query-string";
import UserSearchPartial from "./UserSearchPartial";
import FfpSystemSelector from "../../FfpSystemSelector";

const MileSellerSearchForm = ({ ffpSystemId, ffpSystemName }) => {
    const parsedParams = queryString.parse(document.location.search)

    const [progress, setProgress] = useState(parsedParams.progress)

    const renderProgressOptions = () => {
        return [
            ['', "Choose"],
            ["program_search", "Program Search"],
            ["miles_amount", "Miles Amount"],
            ["minimum_order_accepted", "Minimum Order Accepted"],
            ["mile_price", "Mile Price"],
            ["upload_proof", "Upload Proof"],
            ["done", "Done"]
        ].map((el, x) => {
            return <option key={x} value={el[0]}>{el[1]}</option>
        })
    }

    return <>
        <div className="col-md-2">
            <label>Progress</label>

            <select className="form-control" name="progress" value={progress} onChange={ev => setProgress(ev.target.value)}>
                {renderProgressOptions()}
            </select>
        </div>

        <div className="col-md-2">
            <label>Program</label>
            <FfpSystemSelector selectedId={ffpSystemId} selectedName={ffpSystemName} />
        </div>
    </>
}

export default MileSellerSearchForm