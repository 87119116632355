import React from "react";
import moment from "moment";

import MerchantPerformance from "./MerchantPerformance";

class WalletInPool extends React.Component {
    constructor(props) {
        super(props);

        this.item = props.item;
    }

    render() {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        const {id, userId, seller, priceForMiles, delta, responseTime, sellerIncome, totalServiceFee} = this.item;
        const ts = moment(new Date(this.item.updatedAt * 1000)).format("YYYY-MM-DD");

        return <tr key={id}>
            <td>
                <a href={`/auth/users/${userId}`} target="_blank">{seller}</a>

                <MerchantPerformance userId={userId} />
            </td>
            <td>{this.item.applicationCount}</td>
            <td>{ts}</td>
            <td>&nbsp;</td>
            <td>{formatter.format(priceForMiles)}</td>
            <td>
                {formatter.format(sellerIncome)}
            </td>
            <td>
                {formatter.format(totalServiceFee)}
            </td>
            <td>{formatter.format(delta)}</td>
            <td>{responseTime} hour(s)</td>
            <td>&nbsp;</td>
        </tr>
    }
}

export default WalletInPool
