import React, { useState } from "react";
import RegistrationStatistics from "./RegistrationStatistics";
import WalletStatistics from "./WalletStatistics";
import OrderStatistics from "./OrderStatistics";

const DashboardWrapper = () => {
    const [dateRange, setDateRange] = useState("daily");

    return <>
        <div className="row">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-2 col-md-offset-8">
                        <select className="form-control" onChange={(e) => {
                            setDateRange(e.target.value)
                        }}>
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                        </select>
                    </div>
                </div>

                <RegistrationStatistics selectedRange={dateRange} />
                <WalletStatistics selectedRange={dateRange} />
                <OrderStatistics selectedRange={dateRange} />
            </div>
        </div>
    </>;
};

export default DashboardWrapper;
