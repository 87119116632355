import React from "react"

import MaterialTable from "material-table";
var validate = require("validate.js");
const currency = require('currency.js');

const flightClassNameStore = [
  {
    classCode: "E",
    className: "Economy"
  },
  {
    classCode: "B",
    className: "Business"
  },
]

const constraints = {
  from: {
    presence: true,
    length: 3
  },
  to: {
    presence: true,
    length: 3
  },
  flight_class_name: {
    presence: true,
  },
  airline: {
    presence: true
  },
  tax: {
    presence: false
  },
  miles_count: {
    presence: false
  },
}

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const FFPSelector = (props) => {
  const { ffpList, nextProps } = props;

  const produceElement = (elem) => <option key={elem.name} value={elem.name}>{elem.name}</option>

  return <>
    <select className="form-control" value={nextProps.value} onChange={(e) => { nextProps.onChange(e.target.value) }}>
      <option>--Please Choose--</option>
      {ffpList.map(elem => produceElement(elem))}
    </select>
  </>
}

const FlightClassSelector = ({ nextProps }) => {
  const produceElement = (elem) => <option key={elem.classCode} value={elem.classCode}>{elem.className}</option>

  return <>
    <select className="form-control" value={nextProps.value} onChange={(e) => { nextProps.onChange(e.target.value) }}>
      <option>---Please Choose---</option>
      {flightClassNameStore.map(elem => produceElement(elem))}
    </select>
  </>
}

const FlightClassDisplay = ({ classCode }) => {
  let foundElem = null

  flightClassNameStore.forEach(item => {
    if (item.classCode === classCode) {
      foundElem = item.className
    }
  })

  return <>
    {foundElem}
  </>
}

class Destinations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      priceList: props.records,
      loading: false,
      ffpList: props.ffpList,
      authenticityToken: props.authenticity_token
    }
  }

  render() {
    return <>
      <div className="row">
        <div className="col-md-12">
          <MaterialTable
            isLoading={this.state.loading}
            options={{
              cellStyle: {
                fontSize: 13
              },
              headerStyle: {
                fontSize: 15
              },
              searchFieldStyle: {
                fontSize: 13
              },
              pageSize: 25,
              pageSizeOptions: [25, 50, 150]
            }}
            title="Destinations"
            columns={[
              {
                title: "FROM",
                field: "from",
                editComponent: props => (
                  <input className="form-control" value={props.value} onChange={e => props.onChange(e.target.value)} required={true} />
                )
              },
              {
                title: "TO",
                field: "to",
                editComponent: props => (
                  <input className="form-control" value={props.value} onChange={e => props.onChange(e.target.value)} required={true} />
                )
              },
              {
                title: "AIRLINE",
                field: "airline",
                editComponent: props => (
                  <FFPSelector nextProps={props} ffpList={this.state.ffpList} />
                )
              },
              {
                title: "FLIGHT CLASS",
                field: "flight_class_name",
                editComponent: props => (
                  <FlightClassSelector nextProps={props} />
                ),
                render: rowData => <FlightClassDisplay classCode={rowData.flight_class_name} />
              },
              {
                title: "MILES",
                field: "miles_count",
                type: "numeric",
                editComponent: props => {
                  if (!props.rowData.hasOwnProperty('id')) {
                    return <input className="form-control" value={"From Precalculated"} disabled={true} />
                  } else {
                    return <input className="form-control" value={props.value} type="number" disabled={true} />
                  }
                },
                render: rowData => {
                  return numberWithCommas(rowData.miles_count)
                }
              },
              {
                title: "TAX",
                field: "tax",
                type: "numeric",
                editComponent: props => {
                  if (!props.rowData.hasOwnProperty('id')) {
                    return <input className="form-control" value={"From Precalculated"} disabled={true} />
                  } else {
                    return <input className="form-control" value={props.value} type="number" disabled={true} />
                  }
                },
                render: rowData => {
                  return currency(rowData.tax).format()
                }
              },
              {
                title: "PRICE",
                field: "price",
                type: "numeric",
                editComponent: props => (
                  <input className="form-control" value={"Will calculated"} disabled={true} />
                ),
                render: rowData => {
                  return currency(rowData.price).format()
                }
              },
            ]}
            data={this.state.priceList}
            editable={{
              onRowAdd: newData => {
                const errors = validate(newData, constraints)

                if (!errors) {
                  this.setState({
                    loading: true
                  })

                  return fetch('/redirect_flight/destinations', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'X-CSRF-Token': this.state.authenticityToken
                    },
                    body: JSON.stringify({
                      from_point: newData.from.toUpperCase(),
                      to_point: newData.to.toUpperCase(),
                      airline_name: newData.airline,
                      flight_class_name: newData.flight_class_name,
                    })
                  })
                    .then(response => response.json())
                    .then(response => {
                      if (response.id) {
                        newData.id = response.id
                        newData.from = newData.from.toUpperCase()
                        newData.to = newData.to.toUpperCase()
                        newData.miles_count = response.miles_count
                        newData.tax = response.tax
                        newData.price = response.price

                        let newList = [...this.state.priceList, newData];

                        this.setState({
                          priceList: newList
                        })
                      } else {
                        alert("Unable to save record")
                      }
                    })
                    .catch(err => {
                      console.log(err)

                      alert("Unable to save record")
                    })
                    .finally(() => {
                      this.setState({
                        loading: false
                      })
                    })
                } else {
                  return new Promise((resolve, reject) => {
                    alert("Please fill all fields.")
                    resolve()
                  })
                }
              },
              onRowUpdate: (newData, oldData) => {
                const errors = validate(newData, constraints)

                if (!errors) {
                  this.setState({
                    loading: true
                  })

                  return fetch(`/redirect_flight/destinations/${oldData.id}`, {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                      'X-CSRF-Token': this.state.authenticityToken
                    },
                    body: JSON.stringify({
                      from_point: newData.from.toUpperCase(),
                      to_point: newData.to.toUpperCase(),
                      airline_name: newData.airline,
                      tax: newData.tax,
                      miles_count: newData.miles_count,
                      flight_class_name: newData.flight_class_name,
                    })
                  })
                    .then(response => response.json())
                    .then((response) => {
                      let index;
                      let newList = [...this.state.priceList]

                      for (let x = 0; x < this.state.priceList.length; x++) {
                        if (this.state.priceList[x].id === oldData.id) {
                          index = x;
                        }
                      }

                      newData.from = newData.from.toUpperCase()
                      newData.to = newData.to.toUpperCase()
                      newData.miles_count = response.miles_count
                      newData.tax = response.tax
                      newData.price = response.price

                      newList[index] = newData

                      this.setState({
                        priceList: newList
                      })
                    })
                    .catch(err => {
                      console.log(err)

                      alert("Unable to update record")
                    })
                    .finally(() => {
                      this.setState({
                        loading: false
                      })
                    })
                } else {
                  return new Promise((resolve, reject) => {
                    alert("Please fill all fields.")
                    resolve()
                  })
                }
              },
              onRowDelete: oldData => {
                this.setState({
                  loading: true
                })

                return fetch(`/redirect_flight/destinations/${oldData.id}`, {
                  method: 'DELETE',
                  headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': this.state.authenticityToken
                  }
                })
                  .then(() => {
                    let newList = [...this.state.priceList]

                    newList = newList.filter(function (elem) {
                      return elem.id !== oldData.id
                    })

                    this.setState({ priceList: newList })
                  })
                  .catch(err => {
                    console.log(err)

                    alert("Unable to delete record")
                  })
                  .finally(() => {
                    this.setState({
                      loading: false
                    })
                  })
              },
            }}
          />
        </div>
      </div>
    </>;
  }
}

export default Destinations
