import React, { useState } from "react"
const currency = require('currency.js');
const _ = require('lodash');

import ShowErrors from "./ShowErrors";

const TableItemDisplay = ({item, setSelectedState, token, removeItemFromList}) => {
    const handleDelete = () => {
        fetch(`/flight_price_ranges/${item.id}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': token
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === "deleted") {
                    removeItemFromList(item)
                }
            })
    }

    return <tr>
        <td>#{item.id}</td>
        <td>{item.from || "Any"}</td>
        <td>{item.to || "Any"}</td>
        <td>{item.date_start || "Any"}</td>
        <td>{item.date_end || "Any"}</td>
        <td>{_.capitalize(item.flight_class || "any")}</td>
        <td>{item.miles_start || "Any"}</td>
        <td>{item.miles_end || "Any"}</td>
        <td>{currency(item.price_per_mile).format()}</td>
        <td>{item.service_fee}%</td>
        <td>
            <div className="btn-group" role="group">
                <button className="btn btn-warning btn-sm" onClick={() => setSelectedState("form")}>Edit</button>
                <button className="btn btn-danger btn-sm" onClick={handleDelete}>Remove</button>
            </div>
        </td>
    </tr>
}

const TableItemForm = ({item, setSelectedState, token, updateItemInList}) => {
    const [errors, setErrors] = useState(null)

    const [from, setFrom] = useState(item.from)
    const [to, setTo] = useState(item.to)
    const [flightClass, setFlightClass] = useState(item.flight_class)

    const [milesStart, setMilesStart] = useState(item.miles_start)
    const [milesEnd, setMilesEnd] = useState(item.miles_end)

    const [pricePerMile, setPricePerMile] = useState(item.price_per_mile)

    const [dateStart, setDateStart] = useState(item.date_start)
    const [dateEnd, setDateEnd] = useState(item.date_end)

    const [serviceFee, setServiceFee] = useState(item.service_fee)

    const [rowClass, setRowClass] = useState("")

    const handleSubmit = () => {
        const data = {
            flight_price_range: {
                from: from.toUpperCase(),
                to: to.toUpperCase(),
                flight_class: flightClass,
                date_start: dateStart,
                date_end: dateEnd,
                miles_start: milesStart,
                miles_end: milesEnd,
                price_per_mile: pricePerMile,
                service_fee: serviceFee
            }
        }

        fetch(`/flight_price_ranges/${item.id}`, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': token
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.errors) {
                    setRowClass("danger")

                    setTimeout(() => { setRowClass("") }, 1000)

                    setErrors(data.errors)
                } else {
                    setRowClass("success")

                    setTimeout(() => {
                        setRowClass("")
                        setSelectedState("display")
                        updateItemInList(data)
                    }, 1000)

                    setErrors(null)
                }
            })
    }

    return <>
        <ShowErrors errors={errors} />

        <tr className={rowClass}>
            <td>#{item.id}</td>

            <td>
                <input type="text" value={from || ""} onChange={(elem) => setFrom(
                    elem.target.value) } placeholder="From" className="form-control"
                       style={{ textTransform: "uppercase" }} />
            </td>

            <td>
                <input type="text" value={to || ""} onChange={(elem) => setTo(
                    elem.target.value) } placeholder="To" className="form-control"
                       style={{ textTransform: "uppercase" }} />
            </td>

            <td>
                <input type="date" placeholder="Date Start" className="form-control" value={dateStart || ""} onChange={elem => setDateStart(elem.target.value)} />
            </td>

            <td>
                <input type="date" placeholder="Date End" className="form-control" value={dateEnd || ""} onChange={elem => setDateEnd(elem.target.value)} />
            </td>

            <td>
                <select value={flightClass || ""} onChange={(elem) => {setFlightClass(elem.target.value)}} className="form-control">
                    <option>Any</option>
                    <option value="economy">Economy</option>
                    <option value="business">Business</option>
                </select>
            </td>

            <td>
                <input type="number" step={1} className="form-control" value={milesStart || ""} onChange={(elem) => {setMilesStart(elem.target.value)}} />
            </td>

            <td>
                <input type="number" step={1} className="form-control" value={milesEnd || ""} onChange={(elem) => {setMilesEnd(elem.target.value)}} />
            </td>

            <td>
                <div className="input-group">
                    <span className="input-group-addon">$</span>
                    <input className="form-control" id="input_group_a" aria-describedby="input_group_ab"
                           type="number" step={0.01}
                           value={pricePerMile || 11.0}
                           onChange={(elem) => {setPricePerMile(elem.target.value)}} />
                </div>
            </td>

            <td>
                <div className="input-group">
                    <input className="form-control" id="input_group_a" aria-describedby="input_group_abcd"
                           type="number" step={1}
                           value={serviceFee || 10}
                           onChange={(elem) => {setServiceFee(elem.target.value)}} />
                    <span className="input-group-addon">%</span>
                </div>
            </td>

            <td>
                <div className="btn-group" role="group">
                    <button className="btn btn-success btn-sm" onClick={handleSubmit}>Save</button>
                    <button className="btn btn-danger btn-sm" onClick={() => setSelectedState("display")}>Cancel</button>
                </div>
            </td>
        </tr>
    </>
}

const TableItem = ({item, token, removeItemFromList, updateItemInList}) => {
    const [selectedState, setSelectedState] = useState("display")

    if (selectedState === "display") {
        return <TableItemDisplay removeItemFromList={removeItemFromList} token={token} item={item} setSelectedState={setSelectedState} />
    } else {
        return <TableItemForm token={token} item={item} updateItemInList={updateItemInList} setSelectedState={setSelectedState} />
    }
}

export default TableItem
