import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="lead-list-notes"
export default class extends Controller {
  static targets = ["notesContainer"];

  static values = {
    url: String,
  };
  connect() { }

  async fetchNotes() {
    const resp = await fetch(this.urlValue);
    const body = await resp.text();

    this.notesContainerTarget.innerHTML = body;
  }
}
