import lodash from "lodash"
import React, { useState, useEffect } from "react"

const CurrentPaymentMethods = ({paymentMethods}) => {
    if (paymentMethods.length > 0) {
        return <ul>
            {paymentMethods.map((item) => <li key={item.id}>
                <a href={`/payment_methods/${item.id}`}>{item.name}</a>
            </li>)}
        </ul>
    } else {
        return <>
            No payment method assigned.
        </>
    }
}

const EditMode = ({paymentMethods, all_payment_methods, setPaymentMethods, authenticityToken, record_type, record_id}) => {
    const [selectedAdd, setSelectedAdd] = useState("Please select")

    const availableIds = lodash.xor(all_payment_methods.map(item => item.id), paymentMethods.map(item => item.id))
    const availableMethods = all_payment_methods.filter((item) => {
        return availableIds.includes(item.id)
    })

    const handleAdd = () => {
        if (selectedAdd === undefined || selectedAdd === "Please select") {
            return
        }

        const newItem = all_payment_methods.filter(item => {
            if (item.id == selectedAdd) {
                return item
            }
        })

        if (record_type === "DigitalCode::Region") {
            fetch("/digital_code_region_payment_methods", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': authenticityToken
                },
                body: JSON.stringify({
                    region_id: record_id,
                    payment_method_id: selectedAdd
                })
            }).then(response => {
                if (response.status == 200) {
                    setPaymentMethods([newItem[0], ...paymentMethods])
                    setSelectedAdd("Please select")
                }
            })
        } else if (record_type === "OrderType") {
            fetch("/order_type_payment_methods", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': authenticityToken
                },
                body: JSON.stringify({
                    order_type_id: record_id,
                    payment_method_id: selectedAdd
                })
            }).then(response => {
                if (response.status == 200) {
                    setPaymentMethods([newItem[0], ...paymentMethods])
                    setSelectedAdd("Please select")
                }
            })
        }
    }

    const handleRemove = (itemId) => {
        
        if (record_type === "DigitalCode::Region") {
            fetch(`/digital_code_region_payment_methods/${itemId}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': authenticityToken
                },
                body: JSON.stringify({
                    region_id: record_id,
                    payment_method_id: itemId
                })
            }).then(response => {
                if (response.status == 200) {
                    setPaymentMethods(paymentMethods.filter((item) => {
                        return item.id != itemId
                    }))
                }
            })
        } else if (record_type === "OrderType") {
            fetch(`/order_type_payment_methods/${itemId}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': authenticityToken
                },
                body: JSON.stringify({
                    order_type_id: record_id,
                    payment_method_id: itemId
                })
            }).then(response => {
                if (response.status == 200) {
                    setPaymentMethods(paymentMethods.filter((item) => {
                        return item.id != itemId
                    }))
                }
            })
        }
    }

    return <>
        <div className="row">
            <div className="col-sm-4">
                <p className="lead">Available Payment Methods</p>

                <select className="form-control" value={selectedAdd} onChange={(e) => setSelectedAdd(e.target.value)}>
                    <option>Please select</option>

                    {availableMethods.map((item) => {
                        return <option key={item.id} value={item.id}>{item.name}</option>
                    })}
                </select>

                <br /><br />

                <button className="btn btn-sm btn-success pull-right" onClick={handleAdd} disabled={selectedAdd == "Please select"}>Add</button>
            </div>

            <div className="col-sm-4">
                <p className="lead">Assigned Payment Methods</p>

                <table className="table">
                    <tbody>
                        {paymentMethods.map((item) => {
                            return <tr key={item.id}>
                                <th>
                                    {item.name}
                                </th>

                                <td>
                                    <button className="btn btn-danger btn-sm" onClick={() => handleRemove(item.id)}>Remove</button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </>
}

const OrderTypePaymentMethods = ({payment_methods, all_payment_methods, authenticityToken, record_id, record_type}) => {
    const [editMode, setEditMode] = useState(false)
    const [paymentMethods, setPaymentMethods] = useState([])

    useEffect(() => {
        setPaymentMethods(payment_methods)
    }, [])

    if (editMode) {
        return <>
            <EditMode paymentMethods={paymentMethods} all_payment_methods={all_payment_methods} setPaymentMethods={setPaymentMethods} authenticityToken={authenticityToken} record_type={record_type} record_id={record_id} />

            <br />

            <button className="btn btn-default btn-sm" onClick={() => {setEditMode(false)}}>Close</button>
        </>
    } else {
        return <>
            <CurrentPaymentMethods paymentMethods={paymentMethods} />

            <br />

            <button className="btn btn-warning btn-sm"
                    onClick={() => {setEditMode(true)}}>Edit Assignments</button>
        </>
    }
}

export default OrderTypePaymentMethods
