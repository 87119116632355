import React from "react";
import ReactJson from 'react-json-view';

const JsonViewer = ({content}) => {
    return (
        <ReactJson
            displayDataTypes={false}
            src={content}
            theme="monokai"
        />
    );
};

export default JsonViewer;
