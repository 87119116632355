import React from "react";
import "stylesheets/carousel.scss";
import ImageGallery from 'react-image-gallery';

const WalletValidationImageCarousel = ({pictureUrls}) => {
    return (
        <>
            <ImageGallery
                showPlayButton={false}
                items={pictureUrls}
            />
        </>
    );
};

export default WalletValidationImageCarousel;
