import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lead-list-called"
export default class extends Controller {
  static values = {
    phoneCallAt: String,
    togglePath: String,
  }

  connect() {
    if (this.phoneCallAtValue === 'true') {
      this.element.classList.add("success")
    }
  }

  handleCallToggle() {
    this.phoneCallAtValue = this.phoneCallAtValue === 'true' ? 'false' : 'true'
    this.element.classList.toggle("success")

    fetch(`${this.togglePathValue}`)
  }
}
