import React from "react"

import MaterialTable from "material-table";

class OrderReactions extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            records: props.records
        }
    }

    render () {
        return <div className="row">
            <div className="col-md-12">
                <MaterialTable
                    title="Order Reactions"
                    options={{
                        pageSize: 50,
                        pageSizeOptions: [25, 50, 150]
                    }}
                    columns={[
                        {
                            title: "Full Name",
                            field: "full_name",
                            render: rowData => <a
                                href={rowData.user_link}
                                target="_blank">

                                {rowData.full_name}
                            </a>
                        },
                        {
                            title: "FFP",
                            field: "ffp",
                            render: rowData => {
                                if (rowData.ffp_link !== "-1") {
                                    return <a
                                        href={rowData.ffp_link}
                                        target="_blank">
                                        {rowData.ffp}
                                    </a>
                                }
                            }
                        },
                        {
                            title: "Current Wallet Amount",
                            field: "current_wallet_amount",
                            render: rowData => {
                                if (rowData.wallet_link !== "-1") {
                                    return <a href={rowData.wallet_link}
                                              target="_blank">
                                        {rowData.current_wallet_amount}
                                    </a>
                                }
                            }
                        },
                        {
                            title: "Reaction",
                            field: "reaction"
                        },
                        {
                            title: "Reaction Alt",
                            field: "reaction_alt"
                        },
                        {
                            title: "Order Number",
                            field: "order_number",
                            render: rowData => {
                                if (rowData.order_link !== "-1") {
                                    return <a
                                        href={rowData.order_link}
                                        target="_blank">
                                        {rowData.order_number}
                                    </a>
                                }
                            }
                        },
                        {
                            title: "Reaction Date",
                            field: "reaction_date"
                        },
                        {
                            title: "Mile Amount",
                            field: "mile_amount"
                        },
                        {
                            title: "Flight Date",
                            field: "flight_date"
                        }
                    ]}
                    data={this.state.records}
                />
            </div>
        </div>
    }
}

export default OrderReactions
