import React, { useState, useContext } from "react";
import { ReassignContext } from "./ReassignOrderState";
import * as currency from "currency.js";

const USD = value => currency(value, { symbol: '$', precision: 2, formatWithSymbol: true });

const ApplicableWalletTableRow = (props) => {
    const {availableOptions} = props;

    const { buttonDisabled, makeButtonsDisabled, authenticityToken } = useContext(ReassignContext);

    const { wallet } = props;
    const [tax, setTax] = useState(props.tax);
    const [commission, setCommission] = useState(props.commission);
    const [timeoutSelection, setTimeoutSelection] = useState(null);

    const newTotal = USD(wallet.merchant_fee).add(tax).add(commission);

    const difference = USD(props.payment_amount).subtract(newTotal);
    const differenceSign = difference < 0 && '-';
    const spanClass = differenceSign === '-' ? "text-danger" : "";

    const handleReAssign = () => {
        if(confirm(props.confirmText)) {
            makeButtonsDisabled();
            const data = {
                tax, commission, wallet_id: wallet.id,
                timeoutSelection
            };

            fetch(`/orders/${props.orderId}/reassign.json`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': authenticityToken
                },
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(response => {
                    if (response.message) {
                        makeButtonsDisabled();
                    } else {
                        window.location = response.details_url;
                    }
                });
        }
    };

    return <tr>
        <td>
            <a href={wallet.user_page}>{wallet.user_name}</a>
        </td>

        <td>
            {wallet.minimum_order_amount}
        </td>

        <td>
            {wallet.available_miles}
        </td>

        <td>
            {USD(wallet.price_per_mile).format()}
        </td>

        <td>
            {USD(wallet.merchant_fee).format()}
        </td>

        <td>
            <div className="input-group">
                <div className="input-group-addon">$</div>
                <input
                    className="form-control"
                    name="tax"
                    type="number"
                    step="0.01"
                    value={tax}
                    onChange={(e) => setTax(parseFloat(e.target.value))}
                />
            </div>
        </td>

        <td>
            <div className="input-group">
                <div className="input-group-addon">$</div>
                <input
                    className="form-control"
                    name="commission"
                    type="number"
                    step="0.01"
                    value={commission}
                    onChange={(e) => setCommission(parseFloat(e.target.value))}
                />
            </div>
        </td>

        <td>
            <span className={spanClass}>
                {newTotal.format()}
            </span>
        </td>

        <td>
            <span className={spanClass}>
                {difference.format()}
            </span>
        </td>

        <td>
            <select className="form-control" onChange={event => setTimeoutSelection(event.target.value)}>
                <option>&nbsp;</option>
                {
                    availableOptions.map(item => {
                        return <option value={item.label} key={item.label}>{item.label}</option>
                    })
                }
            </select>
        </td>

        <td>
            <button
                className="btn btn-primary btn-block"
                disabled={buttonDisabled}
                onClick={handleReAssign}
            >
                {props.buttonText}
            </button>
        </td>
    </tr>;
};

export default ApplicableWalletTableRow;
