import React, { useState, useEffect } from "react";
import VisibleToFfpSystems from "./VisibleToFfpSystems";
import VisibleForAll from "./VisibleForAll";

const ListView = ({announcementId, translations}) => {
    const [announcementRelations, setAnnouncementRelations] = useState([]);
    const [ffpSystems, setFfpSystems] = useState([]);

    useEffect(() => {
        fetch(`/ffp_system_announcement_relations?cms_announcement_id=${announcementId}`)
            .then(response => response.json())
            .then(data => setAnnouncementRelations(data));

        fetch(`/ffp_systems.json?per=1000`)
            .then(response => response.json())
            .then(data => setFfpSystems(data));
    }, []);

    const announcementVisibleForAll = announcementRelations.length;

    return (
        <>
            {announcementVisibleForAll ? <VisibleToFfpSystems ffpSystems={ffpSystems} announcementRelations={announcementRelations} /> : <VisibleForAll translations={translations} />}
        </>
    );
};

export default ListView;
