import React from "react";

const MenuMainComponent = () => {
    return <>
        <div className="row">
            <div className="col-md-10">&nbsp;</div>
            <div className="col-md-2">
                <button className="btn btn-success">
                    Add new sector
                </button>
            </div>
        </div>

        <br /><br /><br /><br />

        <div className="row">
            <div className="col-md-12">
                <h2>travel
                    <small>Edit</small>
                </h2>

                <div className="col-md-3">
                    <div className="panel panel-white">
                        <div className="panel-heading clearfix">
                            <h4 className="panel-title">
                                flight
                            </h4>

                            <button className="btn btn-warning btn-sm pull-right">Edit</button>
                        </div>

                        <div className="panel-body">
                            <table className="table">
                                <tr>
                                    <th>Name</th>
                                    <td>flight</td>
                                </tr>
                                <tr>
                                    <th>Disabled</th>
                                    <td>false</td>
                                </tr>
                                <tr>
                                    <th>Order</th>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <th>Description</th>
                                    <td>flight</td>
                                </tr>
                                <tr>
                                    <th>Icon</th>
                                    <td>

                                    </td>
                                </tr>
                                <tr>
                                    <th>Button Color A</th>
                                    <td>#3258a8</td>
                                </tr>
                                <tr>
                                    <th>Button Color B</th>
                                    <td>##123275</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr />
    </>
}

export default MenuMainComponent
