import React, { useState } from "react"
import queryString from "query-string"
import UserSearchPartial from "./UserSearchPartial";
import FfpSystemSelector from "../../FfpSystemSelector";

const TransferBuyerSearchForm = ({ ffpSystemId, ffpSystemName }) => {
    const parsedParams = queryString.parse(document.location.search)

    const [progress, setProgress] = useState(parsedParams.progress)

    const renderProgressOptions = () => {
        return [
            ['', "Choose"],
            ["program_search", "Program Search"],
            ["miles_amount", "Miles Amount"],
            ["select_option", "Delivery Choice"],
            ["transfer_information", "Transfer Information"],
            ["order_detail", "Order Detail / Promo Code"],
            ["payment_method", "Payment Method"],
            ["done", "Done"]
        ].map((el, x) => {
            return <option key={x} value={el[0]}>{el[1]}</option>
        })
    }

    return <>
        <div className="col-md-2">
            <label>Progress</label>

            <select className="form-control" name="progress" value={progress} onChange={ev => setProgress(ev.target.value)}>
                {renderProgressOptions()}
            </select>
        </div>

        <div className="col-md-2">
            <label>Program</label>
            <FfpSystemSelector selectedId={ffpSystemId} selectedName={ffpSystemName} />
        </div>
    </>
}

export default TransferBuyerSearchForm
