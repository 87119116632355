import React, { useState } from "react"

const LoadingIcon = () => {
    return <>
        Loading...
    </>
}

const DetailView = ({wallet}) => {
    return <>
        <div className="row">
            <div className="col-md-3">
                <h3>Total Balance</h3>
                {wallet.total_balance}
            </div>

            <div className="col-md-3">
                <h3>Currency</h3>
                {wallet.currency_code}
            </div>

            <div className="col-md-3">
                <h3>Available</h3>
                {wallet.cash_balance.available}
            </div>

            <div className="col-md-3">
                <h3>Unavailable</h3>
                {wallet.cash_balance.unavailable}
            </div>

            <div className="col-md-12">
                <h3>Transaction Limits</h3>
                <table className="table">
                    <thead>
                    <tr>
                        <th>Max Balance</th>
                        <th>Topup Credit Limit</th>
                        <th>Topup Cash Limit</th>
                        <th>Withdrawal Limit</th>
                        <th>Payment Limit</th>
                        <th>Wallet to wallet Limit</th>
                    </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>{wallet.transaction_limits.max_balance}</td>
                            <td>{wallet.transaction_limits.topup_credit_limit}</td>
                            <td>{wallet.transaction_limits.topup_cash_limit}</td>
                            <td>{wallet.transaction_limits.withdrawal_limit}</td>
                            <td>{wallet.transaction_limits.payment_limit}</td>
                            <td>{wallet.transaction_limits.wallet_to_wallet_limit}</td>
                        </tr>
                    </tbody>
                </table>

                <table className="table">
                    <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Birth Year</th>
                        <th>National ID</th>
                        <th>KYC Status</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td>{wallet.user_kyc_info.first_name}</td>
                        <td>{wallet.user_kyc_info.last_name}</td>
                        <td>{wallet.user_kyc_info.birth_year}</td>
                        <td>{wallet.user_kyc_info.national_id}</td>
                        <td>{wallet.user_kyc_info.kyc_level} - {wallet.user_kyc_info.kyc_level_status}</td>
                    </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </>
}

const StsWalletDetails = ({wallet}) => {
    const [loading, setLoading] = useState(false)
    const [walletData, setWalletData] = useState(wallet)

    if (loading) return <LoadingIcon />

    const handleRefresh = () => {
        setLoading(true)

        fetch(`/sipay/refetch_wallet?user_id=${wallet.owner_id}`)
            .then(resp => resp.json())
            .then((data) => {
                setWalletData(data)
                setLoading(false)
            })
    }

    return <>
        <div className="row">
            <div className="col-md-4 col-md-offset-8 text-right">
                <button className="btn btn-default btn-block" onClick={handleRefresh}>
                    <i className="fa fa-refresh" aria-hidden="true"></i>&nbsp;
                    Refresh
                </button>
            </div>
        </div>

        <hr />

        <DetailView wallet={walletData} />
    </>
}

export default StsWalletDetails
