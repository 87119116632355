import React from "react";
const currency = require('currency.js');

const MatchedRangeView = ({matching_attributes}) => {
    return <>
        <div className="row">
            <div className="col-md-2">
                <h2>{matching_attributes.from} -> {matching_attributes.to}</h2>
                <h3>{matching_attributes.flight_class.toUpperCase()}</h3>
                <h4>{matching_attributes.departure_date}</h4>
                <h4>{matching_attributes.miles_count}</h4>
            </div>

            <div className="col-md-10">
                <table className="table">
                    <thead>
                    <tr>
                        <th>
                            ID
                        </th>

                        <th>
                            From
                        </th>

                        <th>
                            To
                        </th>

                        <th>
                            Date Range
                        </th>

                        <th>
                            Flight Class
                        </th>

                        <th>
                            Mile Range
                        </th>

                        <th>
                            Price Per Mile
                        </th>

                        <th>
                            Service Fee
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td>
                            #{matching_attributes.rule_id}
                        </td>

                        <td>
                            {matching_attributes.record_from || "Any"}
                        </td>

                        <td>
                            {matching_attributes.record_to || "Any"}
                        </td>

                        <td>
                            {matching_attributes.record_date_range_start || "Any"} - {matching_attributes.record_date_range_end || "Any"}
                        </td>

                        <td>
                            {matching_attributes.record_flight_class || "Any"}
                        </td>

                        <td>
                            {matching_attributes.record_mile_range_start || "Any"} - {matching_attributes.record_mile_range_end || "Any"}
                        </td>

                        <td>
                            {currency(matching_attributes.record_price_per_mile).format()}
                        </td>

                        <td>
                            {matching_attributes.record_service_fee}%
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </>
}

export default MatchedRangeView
