import React, { createContext, useReducer } from 'react';

const ReassignReducer = (state, action) => {
    switch (action.type) {
        case 'SWTICH_BUTTON_STATE':
            return {
                buttonDisabled: !state.buttonDisabled
            };
        default:
            return state;
    }
};

const initialState = { buttonDisabled: false };

export const ReassignContext = createContext(initialState);

export const ReassignProvider = ({ children, authenticityToken }) => {
    const [state, dispatch] = useReducer(ReassignReducer, initialState);

    function makeButtonsDisabled() {
        dispatch({
            type: 'SWTICH_BUTTON_STATE',
            payload: null
        });
    }

    return <ReassignContext.Provider value={{
        buttonDisabled: state.buttonDisabled,
        authenticityToken,
        makeButtonsDisabled
    }}>{children}</ReassignContext.Provider>;
};
