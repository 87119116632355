import React from "react";

const VisibleForAll = ({translations}) => {
    return (
        <p className="lead">
            {translations.text}
        </p>
    );
};

export default VisibleForAll;
