import React from "react";
import moment from "moment";
import MerchantPerformance from "../MerchantPerformance";
import currency from "currency.js";

class WalletInPool extends React.Component {
    constructor(props) {
        super(props);

        this.item = props.item;

        this.relatedCurrency = (value) =>
            currency(value, {
                symbol: props.currencySymbol,
                precision: 2,
                formatWithSymbol: true,
                pattern: "# !",
            });

        this.state = {
            loading: false,
            deleted: false,
        };
    }

    removeFromPool() {
        fetch(
            `/order_pools/${this.props.orderPoolId}/order_pool_items/${this.props.item.poolItemId}`,
            {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-CSRF-Token": this.props.authenticityToken,
                },
            }
        )
            .then((resp) => resp.json())
            .then(() => {
                this.setState({
                    loading: false,
                    deleted: true,
                });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                    deleted: false,
                });
            });
    }

    rowClass() {
        if (!this.state.loading && this.state.deleted) {
            return "danger";
        } else if (this.state.loading) {
            return "info";
        }

        return "";
    }

    render() {
        const {
            id,
            userId,
            seller,
            priceForMiles,
            delta,
            responseTime,
            sellerIncome,
            totalServiceFee,
            availableAmount,
        } = this.item;
        const ts = moment(new Date(this.item.updatedAt * 1000)).format(
            "YYYY-MM-DD"
        );

        return (
            <tr key={id} className={this.rowClass()}>
                <td>
                    <a href={`/auth/users/${userId}`} target="_blank">
                        {seller}
                    </a>

                    <MerchantPerformance userId={userId} />
                </td>
                <td>{this.item.applicationCount}</td>
                <td>{ts}</td>
                <td>{this.relatedCurrency(availableAmount / 100).format()}</td>
                <td>{this.relatedCurrency(priceForMiles).format()}</td>
                <td>{this.relatedCurrency(sellerIncome).format()}</td>
                <td>{this.relatedCurrency(totalServiceFee).format()}</td>
                <td>{this.relatedCurrency(delta).format()}</td>
                <td>{responseTime} hour(s)</td>
                <td>
                    <button
                        onClick={() => {
                            this.removeFromPool();
                        }}
                        className="btn btn-danger"
                    >
                        Remove
                    </button>
                </td>
            </tr>
        );
    }
}

export default WalletInPool;
