import React, {useState} from "react";

const CalculatePriceOfMilesForFFP = ({ffpSystemId}) => {
    const [loading, setLoading] = useState(false);
    const [querySuccess, setQuerySuccess] = useState();
    const [errorMessage, setErrorMessage] = useState('');

    const [cheapest, setCheapest] = useState(0);
    const [fastest, setFastest] = useState(0);

    const [givenMiles, setGivenMiles] = useState(0);
    const [givenTax, setGivenTax] = useState(0);

    const fetchPriceResult = (e) => {
        var httpResult = null;

        e.preventDefault();
        setLoading(true);
        setErrorMessage('');
        setQuerySuccess(null);

        fetch(`/ffp_systems/${ffpSystemId}/calculate_miles_count_prices?miles_count=${givenMiles}&tax=${givenTax}`)
            .then(response => {
                httpResult = response.status === 200;
                return response.json();
            })
            .then(data => {
                if (httpResult) {
                    setCheapest(data.cheapest);
                    setFastest(data.fastest);
                } else {
                    setErrorMessage(data.message);
                }

                setQuerySuccess(httpResult);
                setLoading(false);
            })
            .catch(err => {
                alert(err);
            })
        ;
    };

    const successResult = () => {
       return <div className="row">
           <div className="col-md-4">
               <p className="lead">
                   The cheapest option is {cheapest}
               </p>
           </div>

           <div className="col-md-4">
               <p className="lead">
                   The fastest option is {fastest}
               </p>
           </div>
       </div>
    };

    return (
        <div>
            { !loading && errorMessage && <div className="panel panel-danger">
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            </div> }

            <form className="form-inline">
                <div className="form-group">
                    <label htmlFor="milesCount">Miles Count</label>
                    <input type="number"
                           id="milesCount"
                           className="form-control input-sm"
                           minimum="0"
                           value={givenMiles}
                           onChange={(e) => setGivenMiles(e.target.value)}
                           step="1" />
                </div>
                &nbsp;&nbsp;

                <div className="form-group">
                    <label htmlFor="milesCount">Tax</label>
                    <div className="input-group">
                        <div className="input-group-addon">$</div>
                        <input type="number"
                               id="tax"
                               className="form-control input-sm"
                               minimum="0"
                               value={givenTax}
                               onChange={(e) => setGivenTax(e.target.value)}
                               step="0.01" />
                    </div>
                </div>
                &nbsp;&nbsp;

                <button onClick={(e) => fetchPriceResult(e)} className="btn btn-primary btn-sm">Simulate search</button>
            </form>

            { querySuccess && successResult() }
            { loading && <p>Loading...</p> }
        </div>
    );
};

export default CalculatePriceOfMilesForFFP;
