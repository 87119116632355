import React, { useState } from "react"
import ShowErrors from "./ShowErrors";

const AddNew = ({ token, ffpSystemId, addItemToList }) => {
    const [errors, setErrors] = useState(null)

    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")
    const [flightClass, setFlightClass] = useState("")

    const [milesStart, setMilesStart] = useState("")
    const [milesEnd, setMilesEnd] = useState("")

    const [pricePerMile, setPricePerMile] = useState("12")
    const [serviceFee, setServiceFee] = useState(10)

    const [rowClass, setRowClass] = useState("")

    const handleSubmit = () => {
        const data = {
            flight_price_range: {
                from: from.toUpperCase(),
                to: to.toUpperCase(),
                flight_class: flightClass,
                miles_start: milesStart,
                miles_end: milesEnd,
                price_per_mile: pricePerMile,
                redirect_flight_ffp_system_id: ffpSystemId,
                service_fee: serviceFee,
            }
        }

        fetch(`/redirect_flight/flight_price_ranges`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': token
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.errors) {
                    setRowClass("danger")
                    setTimeout(() => { setRowClass("active") }, 1000)
                    setErrors(data.errors)
                } else {
                    setRowClass("success")
                    setTimeout(() => { setRowClass("active") }, 1000)
                    setErrors(null)

                    addItemToList(data)
                }
            })
    }

    return <>
        <table className="table">
            <tbody>
                <ShowErrors errors={errors} />

                <tr className={rowClass}>
                    <td>
                        <input type="text" value={from || ""} onChange={(elem) => setFrom(
                            elem.target.value)} placeholder="From" className="form-control"
                            style={{ textTransform: "uppercase" }} />
                    </td>

                    <td>
                        <input type="text" value={to || ""} onChange={(elem) => setTo(
                            elem.target.value)} placeholder="To" className="form-control"
                            style={{ textTransform: "uppercase" }} />
                    </td>

                    <td>
                        <select value={flightClass || ""} onChange={(elem) => { setFlightClass(elem.target.value) }} className="form-control">
                            <option>Any</option>
                            <option value="E">Economy</option>
                            <option value="B">Business</option>
                        </select>
                    </td>

                    <td>
                        <input type="number" step={1} placeholder="Mile Start" className="form-control" value={milesStart || ""} onChange={(elem) => { setMilesStart(elem.target.value) }} />
                    </td>

                    <td>
                        <input type="number" step={1} placeholder="Mile End" className="form-control" value={milesEnd || ""} onChange={(elem) => { setMilesEnd(elem.target.value) }} />
                    </td>

                    <td>
                        <div className="input-group">
                            <span className="input-group-addon">$</span>
                            <input className="form-control" id="input_group_a" aria-describedby="input_group_abc"
                                type="number" step={0.01}
                                value={pricePerMile || 11.0} onChange={(elem) => { setPricePerMile(elem.target.value) }} />
                        </div>
                    </td>

                    <td>
                        <div className="input-group">
                            <input className="form-control" id="input_group_a" aria-describedby="input_group_abcd"
                                type="number" step={1}
                                value={serviceFee || 10}
                                onChange={(elem) => { setServiceFee(elem.target.value) }} />
                            <span className="input-group-addon">%</span>
                        </div>
                    </td>

                    <td>
                        <button className="btn btn-success btn-sm" onClick={handleSubmit}>Add</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </>
}

export default AddNew
