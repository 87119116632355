import React, { useState, useEffect } from "react";
import lodash from "lodash";
import MaterialTable from "material-table";

const GsmListHandler = ({ initialValues, fieldName }) => {
    const [buildValue, setBuildValue] = useState("");
    const [gsmList, setGsmList] = useState([]);

    useEffect(() => {
        setGsmList(initialValues);
        setBuildValue(JSON.stringify(initialValues));
    }, []);

    const handleRowAdd = ({ country_code, gsm }) => {
        return new Promise((resolve, reject) => {
            let list = [
                ...gsmList,
                {
                    country_code,
                    gsm,
                },
            ];

            setGsmList(list);
            setBuildValue(JSON.stringify(list));

            resolve();
        });
    };

    const handleRowUpdate = (oldRow, newRow) => {
        return new Promise((resolve, reject) => {
            let index = null;
            let newList = [...gsmList];

            for (let x = 0; x < gsmList.length; x++) {
                if (
                    gsmList[x].gsm === oldRow.gsm &&
                    gsmList[x].country_code === oldRow.country_code
                ) {
                    index = x;
                }
            }

            newList[index] = newRow;

            setGsmList(newList);
            setBuildValue(JSON.stringify(newList));

            resolve();
        });
    };

    const handleRowDelete = (row) => {
        return new Promise((resolve, reject) => {
            let newList = lodash.reject([...gsmList], function(a) {
                return row.gsm === a.gsm && row.country_code === a.country_code;
            });

            setGsmList(newList);
            setBuildValue(JSON.stringify(newList));

            resolve();
        });
    };

    return (
        <>
            <input
                id="hiddenGsmList"
                type="hidden"
                name={"promo_code[" + fieldName + "]"}
                value={buildValue}
            />

            <MaterialTable
                options={{
                    cellStyle: {
                        fontSize: 13,
                        textAlign: "right",
                    },
                    headerStyle: {
                        fontSize: 15,
                        textAlign: "right",
                    },
                    searchFieldStyle: {
                        fontSize: 13,
                    },
                }}
                title="GSM List"
                columns={[
                    {
                        title: "Country Code",
                        field: "country_code",
                    },
                    {
                        title: "GSM",
                        field: "gsm",
                    },
                ]}
                data={gsmList}
                editable={{
                    onRowAdd: (rowData) => handleRowAdd(rowData),
                    onRowUpdate: (newData, oldData) => handleRowUpdate(oldData, newData),
                    onRowDelete: (rowData) => handleRowDelete(rowData),
                }}
            />
        </>
    );
};

export default GsmListHandler;
