import React, { useState } from "react";
const _ = require('lodash');
const lodash_contrib = require("lodash-contrib")

import TableItem from "./TableItem";
import AddNew from "./AddNew";
import SimulateSearch from "./SimulateSearch";
import FilterForm from "./FilterForm";
import DefaultRange from "./DefaultRange";

const MainContainer = ({default_range, price_ranges, token, ffp_system_id, user_custom_price_usage_id}) => {
    const [listItems, setListItems] = useState(price_ranges)
    const [filteredRecords, setFilteredRecords] = useState(price_ranges)
    const [currentFilter, setCurrentFilter] = useState({})

    const removeItemFromList = (item) => {
        let items = listItems.filter((i) => {
            return i.id !== item.id
        })

        setListItems(items)
        setFilteredRecords(items)
        handleFilter(currentFilter)
    }

    const updateItemInList = (item) => {
        let index = _.findIndex(listItems, (e) => {
            return e.id == item.id;
        }, 0);

        let items = [...listItems]

        items[index] = item

        setListItems(items)
        setFilteredRecords(items)
        handleFilter(currentFilter)
    }

    const addItemToList = (item) => {
        let items = [item, ...listItems]

        setListItems(items)
        setFilteredRecords(listItems)
        handleFilter(currentFilter)
    }

    const handleFilter = (filterParams) => {
        setCurrentFilter(filterParams)

        if (!_.isEmpty(filterParams)) {
            let filtered = listItems.filter(item => {
                if (filterParams.id && (_.toNumber(filterParams.id) !== item.id)) {
                    return false
                }

                if (!lodash_contrib.strContains(item.from || "", filterParams.from.toUpperCase())) {
                    return false
                }

                if (!lodash_contrib.strContains(item.to || "", filterParams.to.toUpperCase())) {
                    return false
                }

                if (filterParams.flightClass && (filterParams.flightClass !== item.flight_class)) {
                    return false
                }

                // Filter miles range and date range is not working.
                //
                // if (filterParams.dateStart && (filterParams.dateStart !== item.dateStart)) {
                //     return false
                // }
                //
                // if (filterParams.dateEnd && (filterParams.dateEnd !== item.dateEnd)) {
                //     return false
                // }
                //

                // if (!((filterParams.milesStart && filterParams.milesEnd) && (item.miles_start && item.miles_end) && (item.miles_start >= _.toNumber(filterParams.milesStart) && (item.miles_end <= _.toNumber(filterParams.milesEnd))))) {
                //     return false
                // }

                return true
            })

            setFilteredRecords(filtered)
        }
    }

    const resetFilter = () => {
        setFilteredRecords(listItems)
    }

    return <>
        <div className="row">
            <div className="col-md-12">
                {!user_custom_price_usage_id && <SimulateSearch ffpSystemId={ffp_system_id} />}
            </div>
        </div>

        <hr />

        <h3>Records</h3>

        <div className="row">
            <div className="col-md-12">
                <AddNew token={token} ffpSystemId={ffp_system_id} addItemToList={addItemToList} userCustomPriceUsageId={user_custom_price_usage_id} />

                <table className="table">
                    <thead>
                    {<FilterForm resetFilter={resetFilter} handleFilter={handleFilter} />}
                    </thead>

                    <tbody>

                    {default_range && <DefaultRange default_range={default_range} token={token} />}

                    {filteredRecords.map(item => <TableItem removeItemFromList={removeItemFromList} updateItemInList={updateItemInList} token={token} item={item} key={item.id} />)}
                    </tbody>
                </table>
            </div>
        </div>

    </>
}

export default MainContainer
