import React from "react";

class EditView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ffpSystems: [],
            presentRelations: [],
            availableFfpSystems: [],

            itemWillAddRelations: null,
            itemWillRemovedFromRelations: null,

            isLoading: true
        };
    }

    async componentDidMount() {
        const ffpSystemsResponse = await fetch(`/ffp_systems.json?per=1000`);
        const ffpSystemsAsJson = await ffpSystemsResponse.json();

        const presentRelations = await fetch(`/ffp_system_announcement_relations?cms_announcement_id=${this.props.announcementId}`);
        const presentRelationsAsJson = await presentRelations.json();

        const availableFfpSystems = ffpSystemsAsJson.filter((ffpSystem) => {
            return !presentRelationsAsJson.map(relation => relation.ffp_system_id).includes(ffpSystem.id);
        });

        this.setState({
            ffpSystems: ffpSystemsAsJson,
            presentRelations: presentRelationsAsJson,
            isLoading: false,

            availableFfpSystems
        });
    }

    async addToRelations(event) {
        event.preventDefault();

        if (!this.state.itemWillAddRelations) { return; }

        this.setState({ isLoading: true });

        let formData = new FormData();
        formData.append(
            "ffp_system_announcement_relation[cms_announcement_id]",
            this.props.announcementId
        );
        formData.append(
            "ffp_system_announcement_relation[ffp_system_id]",
            this.state.itemWillAddRelations
        );

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        const response = await fetch('/ffp_system_announcement_relations', requestOptions);

        if (response.status === 201) {
            const asJson = await response.json();

            const newAvailableFfpSystems = this.state.availableFfpSystems.filter((item) => {
                return item.id.toString() !== this.state.itemWillAddRelations.toString()
            });

            this.setState({
                presentRelations: [asJson, ...this.state.presentRelations],
                availableFfpSystems: newAvailableFfpSystems
            });
        }

        this.setState({ isLoading: false });
    }

    async removeFromRelations(event) {
        event.preventDefault();

        if (!this.state.itemWillRemovedFromRelations) { return; }

        this.setState({ isLoading: true });

        const requestOptions = {
            method: 'DELETE'
        };

        const response = await fetch(
            `/ffp_system_announcement_relations/${this.state.itemWillRemovedFromRelations}`,
            requestOptions
        );

        if (response.status === 204) {
            const ffpSystemIdToBeAdded = this.state.presentRelations.find((relation) => {
               return relation.id.toString() === this.state.itemWillRemovedFromRelations.toString()
            }).ffp_system_id;
            const ffpSystemToBeAdded = this.state.ffpSystems.find(ffpSystem => ffpSystem.id.toString() === ffpSystemIdToBeAdded.toString());
            const newPresentRelations = this.state.presentRelations.filter((relation) => {
                return relation.id.toString() !== this.state.itemWillRemovedFromRelations.toString()
            });

            this.setState({
                presentRelations: newPresentRelations,
                availableFfpSystems: [ffpSystemToBeAdded, ...this.state.availableFfpSystems]
            });
        }

        this.setState({ isLoading: false });
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-4">
                    <form onSubmit={this.addToRelations.bind(this)}>
                        <select
                            className="form-control"
                            multiple={false}
                            ref={this.itemWillAdd}
                            onChange={(event) => { this.setState({ itemWillAddRelations: event.target.value }) }}
                        >
                            <option>
                                {this.props.translations.pleaseChoose}
                            </option>

                            {this.state.availableFfpSystems.map(ffpSystem => {
                                return <option
                                    value={ffpSystem.id}
                                    key={ffpSystem.id}
                                >
                                    {ffpSystem.name}
                                </option>
                            })}
                        </select>

                        <br/>

                        <button
                            className="btn btn-success"
                            disabled={!this.state.availableFfpSystems.length || this.state.isLoading}
                        >
                            {this.props.translations.add}
                        </button>
                    </form>
                </div>

                <div className="col-sm-4">
                    <form
                        onSubmit={this.removeFromRelations.bind(this)}
                    >
                        <select
                            multiple={false}
                            onChange={(event) => { this.setState({ itemWillRemovedFromRelations: event.target.value }) }}
                            className="form-control"
                        >
                            <option>
                                {this.props.translations.pleaseChoose}
                            </option>

                            {this.state.presentRelations.map(relation => {
                                return <option
                                    value={relation.id}
                                    key={relation.id}
                                >
                                    {relation.ffp_system_name}
                                </option>
                            })}
                        </select>

                        <br/>

                        <button
                            className="btn btn-danger"
                            disabled={!this.state.presentRelations.length || this.state.isLoading}
                        >
                            {this.props.translations.remove}
                        </button>
                    </form>
                </div>
            </div>
        );
    }
};

export default EditView;
