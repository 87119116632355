import React, { useState } from "react"
import queryString from "query-string"

import { renderGenericsSelectOptions } from "./generics_select_options";
import FlightBuyerSearchForm from "./FlightBuyerSearchForm";
import DefaultSearchForm from "./DefaultSearchForm";
import TransferBuyerSearchForm from "./TransferBuyerSearchForm";
import MileSellerSearchForm from "./MileSellerSearchForm";
import UserSearchPartial from "./UserSearchPartial";

const SearchMainWrapper = ({ searchedFfpName, searchedFfpId }) => {
    const parsedParams = queryString.parse(document.location.search)

    const [selectedGeneric, setSelectedGeneric] = useState(parsedParams.generic)
    const [operationStatus, setOperationStatus] = useState(parsedParams.operation_status)

    const [orderDateBegin, setOrderDateBegin] = useState(parsedParams.order_date_begin)
    const [orderDateEnd, setOrderDateEnd] = useState(parsedParams.order_date_end)

    const [eventDateStart, setEventDateStart] = useState(parsedParams.event_date_start)
    const [eventDateEnd, setEventDateEnd] = useState(parsedParams.event_date_end)

    const renderPartialForm = (given) => {
        if (given === "flight_buyer") {
            return <FlightBuyerSearchForm ffpSystemId={searchedFfpId} ffpSystemName={searchedFfpName} />
        } else if (given === "transfer_buyer") {
            return <TransferBuyerSearchForm ffpSystemId={searchedFfpId} ffpSystemName={searchedFfpName} />
        } else if (given === "mile_seller") {
            return <MileSellerSearchForm ffpSystemId={searchedFfpId} ffpSystemName={searchedFfpName} />
        }

        return <DefaultSearchForm />
    }

    const showOrderDateFilter = (generic) => {
        return ["flight_buyer", "transfer_buyer", "game_buyer", "digital_code_buyer"].includes(generic)
    }

    return <>
        <div className="panel panel-default">
            <div className="panel-body">
                <form method="GET">
                    <div className="row">
                        <div className="col-md-2">
                            <label>Event Date Start</label>
                            <input type="date" className="form-control" name="event_date_start"
                                value={eventDateStart} onChange={(e) => setEventDateStart(e.target.value)} />
                        </div>

                        <div className="col-md-2">
                            <label>Event Date End</label>
                            <input type="date" className="form-control" name="event_date_end"
                                value={eventDateEnd} onChange={(e) => setEventDateEnd(e.target.value)} />
                        </div>

                        <UserSearchPartial />

                        <div className="col-md-1">
                            <label>Operation</label>

                            <select name="operation_status" className="form-control" value={operationStatus} onChange={(e) => setOperationStatus(e.target.value)}>
                                <option value={''}>Choose</option>
                                <option value="pending">Pending</option>
                                <option value="in_progress">In Progress</option>
                                <option value="problem">Problem</option>
                                <option value="resolved">Resolved</option>
                            </select>
                        </div>

                        <div className="col-md-2">
                            <label>Generic</label>

                            <select name="generic" className="form-control" value={selectedGeneric} onChange={(ev) => setSelectedGeneric(ev.target.value)}>
                                {renderGenericsSelectOptions()}
                            </select>
                        </div>

                        {renderPartialForm(selectedGeneric)}
                    </div>

                    <div className="row">
                        {selectedGeneric && showOrderDateFilter(selectedGeneric) && <>
                            <div className="col-md-2">
                                <label>Order Date</label>

                                <input type="date" name="order_date_begin" value={orderDateBegin} onChange={(e) => setOrderDateBegin(e.target.value)} className="form-control" />
                                <input type="date" name="order_date_end" value={orderDateEnd} onChange={(e) => setOrderDateEnd(e.target.value)} className="form-control" />
                            </div>
                        </>}
                    </div>

                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button className="btn btn-primary">Search</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
}

export default SearchMainWrapper
