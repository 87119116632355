import React from "react";

export const renderGenericsSelectOptions = () => {
    return [
        ["", "Please choose"],
        ["flight_buyer", "Flight Buyer"],
        ["game_buyer", "Game Buyer"],
        ["transfer_buyer", "Transfer Buyer"],
        ["game_seller", "Game Seller"],
        ["mile_seller", "Mile Seller"],
        ["digital_code_buyer", "Digital Code Buyer"],
        ["customer_role_change", "Customer Role Change"]
    ].map((k, v) => {
        return <option key={v} value={k[0]}>
            {k[1]}
        </option>
    })
}
