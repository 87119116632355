import React from "react"

const OrderPoolLoading = () => {
    return <>
        <tr>
            <td className="text-center" colSpan={6}>
                <h3>Loading...</h3>
            </td>
        </tr>
    </>
}

export default OrderPoolLoading
