import React from "react";
import QRCode from "qrcode.react";

const QRCodeDisplay = ({ firstName, lastName, phone, email }) => {
    const content = `BEGIN:VCARD
VERSION:3.0
N: ${lastName};${firstName}
FN:${firstName}  ${lastName}
TEL;CELL:${phone}
TEL;FAX:
EMAIL;WORK;INTERNET:${email}
END:VCARD`;

    return <>
        <pre>{content}</pre>
        <QRCode value={content} size={256} />
    </>;
};

export default QRCodeDisplay;
