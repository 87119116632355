import React from "react";
import moment from 'moment';
import PresetDateRangePicker from './PresetDateRangePicker';

const FilterDateTimeSelector = (props) => {
    let startDate = null;
    let endDate = null;

    if(props.endCreatedAtContent) {
        endDate = moment(props.endCreatedAtContent, "YYYY.MM.DD");
    }

    if(props.beginCreatedAtContent) {
        startDate = moment(props.beginCreatedAtContent, "YYYY.MM.DD");
    }

    const presets = [
        {
            text: 'All Time',
            start: moment().subtract('50', 'year'),
            end: moment()
        },
        {
            text: '12 months',
            start: moment().subtract('12', 'month'),
            end: moment(),
        },
        {
            text: '3 months',
            start: moment().subtract('3', 'month'),
            end: moment(),
        },
        {
            text: '1 month',
            start: moment().subtract('1', 'month'),
            end: moment(),
        },
        {
            text: '1 week',
            start: moment().subtract('1', 'week'),
            end: moment(),
        },
        {
            text: 'Today',
            start: moment(),
            end: moment(),
        }
    ];

    return (
        <>
            <PresetDateRangePicker
                displayFormat="DD/MM/YYYY"
                presets={presets}
                isOutsideRange={() => false}
                showClearDates={true}
                initialStartDate={startDate}
                initialEndDate={endDate}
                beginQueryName={props.beginQueryName || "created_at_begin"}
                endQueryName={props.endQueryName || "created_at_end"}
            />
        </>
    );
};

export default FilterDateTimeSelector;
