import React, { useState } from "react"
import * as _ from "lodash"
const currency = require('currency.js');

const ShowResult = ({ result }) => {
    if (_.isEmpty(result)) {
        return <></>
    }

    return <>
        <div className="row">
            <div className="col-md-12">
                <table className="table">
                    <tbody>
                    <tr>
                        <th>ID</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Date Start</th>
                        <th>Date End</th>
                        <th>Flight Class</th>
                        <th>Miles Start</th>
                        <th>Miles End</th>
                        <th>Price Per Mile</th>
                        <th>Service Fee</th>
                        <th>&nbsp;</th>
                    </tr>
                    </tbody>
                    <tbody>
                    <tr>
                        <td>
                            {result.id}
                        </td>

                        <td>
                            {result.from || "Any"}
                        </td>

                        <td>
                            {result.to || "Any"}
                        </td>

                        <td>
                            {result.date_start || "Any"}
                        </td>

                        <td>
                            {result.date_end || "Any"}
                        </td>

                        <td>
                            {result.flight_class || "Any"}
                        </td>

                        <td>
                            {result.miles_start || "Any"}
                        </td>

                        <td>
                            {result.miles_end || "Any"}
                        </td>

                        <td>
                            {currency(result.price_per_mile).format()}
                        </td>

                        <td>
                            {result.service_fee}%
                        </td>

                        <td>
                            &nbsp;
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </>
}

const SimulateSearch = ({ ffpSystemId }) => {
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")
    const [departureDate, setDepartureDate] = useState("")
    const [flightClass, setFlightClass] = useState("")
    const [miles, setMiles] = useState(0)

    const [result, setResult] = useState({})

    const handleSimulateSearch = () => {
        const queryString = `ffp_system_id=${ffpSystemId}&from=${from.toUpperCase()}&to=${to.toUpperCase()}&departure_date=${departureDate}&flight_class=${flightClass}&miles_count=${miles}`

        fetch(`/flight_price_ranges/search?${queryString}`)
            .then(response => response.json())
            .then(data => {
                setResult(data)
            })
    }

    return <>
        <table className="table">
            <tbody>
            <tr>
                <td>
                    <input type="text" placeholder="From" className="form-control"
                           value={from} onChange={(ev) => setFrom(ev.target.value) }
                           style={{ textTransform: "uppercase" }} />
                </td>

                <td>
                    <input type="text" placeholder="To" className="form-control"
                           value={to} onChange={(ev) => setTo(ev.target.value) }
                           style={{ textTransform: "uppercase" }} />
                </td>

                <td>
                    <input type="date" placeholder="Date" className="form-control"
                           value={departureDate} onChange={(ev) => setDepartureDate(ev.target.value) } />
                </td>

                <td>
                    <select className="form-control" value={flightClass} onChange={(ev) => setFlightClass(ev.target.value) }>
                        <option value="economy">Economy</option>
                        <option value="business">Business</option>
                    </select>
                </td>

                <td>
                    <input type="number" placeholder="Miles" className="form-control"
                           value={miles} onChange={(ev) => setMiles(ev.target.value) } />
                </td>

                <td>
                    <button className="btn btn-active" onClick={handleSimulateSearch}>
                        Simulate
                    </button>
                </td>
            </tr>
            </tbody>
        </table>

        {result && <ShowResult result={result} />}
    </>
}

export default SimulateSearch
