import React from "react";
import moment from "moment";
import MerchantPerformance from "../MerchantPerformance";
const _ = require("lodash");
import * as currency from "currency.js";

class ApplicableWallet extends React.Component {
    constructor(props) {
        super(props);

        const relatedCurrency = (value) =>
            currency(value, {
                symbol: props.currencySymbol,
                precision: 2,
                formatWithSymbol: true,
                pattern: "# !",
            });

        this.state = {
            hours: this.props.defaultCompleteHours,
            buttonDisabled: false,
            pricePerMile: props.item.priceForMiles,
            delta: props.item.delta,
            milesCount: _.toNumber(props.milesCount),
            paymentAmount: _.toNumber(props.paymentAmount),
            serviceFee: _.toNumber(props.serviceFee),
            relatedCurrency: relatedCurrency,
        };

        this.item = props.item;
        this.item.responseTime = this.state.hours;
    }

    calculateSellerIncome() {
        return (this.state.milesCount / 100) * this.state.pricePerMile;
    }

    calculateTotalServiceFee() {
        const sellerIncome = this.calculateSellerIncome();

        return this.state.paymentAmount - sellerIncome;
    }

    calculateDelta() {
        return this.calculateTotalServiceFee() - this.state.serviceFee;
    }

    handleHourChange(hour) {
        this.item.responseTime = hour;
        this.setState({ hours: hour });
    }

    handlePricePerMileChange(price) {
        this.setState({
            pricePerMile: price,
        });
    }

    handleClick() {
        this.props.changeLoading(true);
        this.setState({ buttonDisabled: true });

        fetch(
            `/order_pools/${this.props.orderPoolId}/order_pool_items?wallet_id=${this.item.id}&merchant_respond_timeout=${this.state.hours}&price_per_mile=${this.state.pricePerMile}`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-CSRF-Token": this.props.authenticityToken,
                },
            }
        )
            .then((resp) => resp.json())
            .then((data) => {
                if (data.status === "ok") {
                    this.props.changeLoading(false);

                    const item = { ...this.props.item };

                    item.poolItemId = data.pool_item_id;
                    item.availableAmount = data.available_amount;
                    item.priceForMiles = this.state.pricePerMile;
                    item.sellerIncome = this.calculateSellerIncome();
                    item.totalServiceFee = this.calculateTotalServiceFee();
                    item.delta = this.calculateDelta();

                    this.props.addToPool(item);
                    this.setState({ buttonDisabled: false });
                } else {
                    alert("Unable to add to pool.");
                    this.props.changeLoading(false);
                    this.setState({ buttonDisabled: false });
                }
            })
            .catch(() => {
                alert("Unable to add to pool.");
                this.props.changeLoading(false);
                this.setState({ buttonDisabled: false });
            });
    }

    render() {
        const { id, userId, seller, availableAmount } = this.item;
        const ts = moment(new Date(this.item.updatedAt * 1000)).format(
            "YYYY-MM-DD"
        );

        return (
            <tr key={id}>
                <td>
                    <a href={`/auth/users/${userId}`} target="_blank">
                        {seller}
                    </a>

                    <MerchantPerformance userId={userId} />
                </td>
                <td>{this.item.applicationCount}</td>
                <td>{ts}</td>
                <td>{this.state.relatedCurrency(availableAmount / 100).format()}</td>
                <td>
                    <div className="input-group">
                        <span className="input-group-addon">
                            {this.props.currencySymbol}
                        </span>
                        <input
                            id="input_group_a"
                            aria-describedby="input_group_abc"
                            type="number"
                            step="0.01"
                            value={this.state.pricePerMile}
                            className="form-control"
                            onChange={(e) => {
                                this.handlePricePerMileChange(e.target.value);
                            }}
                        />
                    </div>
                </td>
                <td>
                    {this.state.relatedCurrency(this.calculateSellerIncome()).format()}
                </td>

                <td>
                    {this.state.relatedCurrency(this.calculateTotalServiceFee()).format()}
                </td>

                <td>{this.state.relatedCurrency(this.calculateDelta()).format()}</td>

                <td>
                    <div className="input-group">
                        <input
                            min={2}
                            max={36}
                            step={1}
                            type="number"
                            value={this.state.hours}
                            onChange={(e) => {
                                this.handleHourChange(e.target.value);
                            }}
                            className="form-control"
                        />
                        <span className="input-group-addon">hours</span>
                    </div>
                </td>
                <td>
                    <button
                        className="btn btn-success"
                        onClick={() => this.handleClick()}
                        disabled={this.state.buttonDisabled}
                    >
                        Add to Pool
                    </button>
                </td>
            </tr>
        );
    }
}

export default ApplicableWallet;
