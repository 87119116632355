import React, {useState} from "react";
import styled from 'styled-components';

const Span = styled.span`
    cursor: pointer;
    text-decoration: underline;
`;

const FfpSystemValidationRules = (props) => {
    const amountInUse = props[0].ffpSystemValues.amount_in_use;
    const minimumOrderAmountInUse = props[0].ffpSystemValues.minimum_order_amount_in_use;

    const amountPrimaryLabel = amountInUse ? props[0].translations.activeValues.ffpSystem : props[0].translations.activeValues.system;
    const amountSecondaryLabel = !amountInUse ? props[0].translations.switches.showFfp : props[0].translations.switches.showSystem;
    const [amountDisplayChoice, setAmountDisplayChoice] = useState('primary');

    const minimumOrderAmountPrimaryLabel = minimumOrderAmountInUse ? props[0].translations.activeValues.ffpSystem : props[0].translations.activeValues.system;
    const minimumOrderAmountSecondaryLabel = !minimumOrderAmountInUse ? props[0].translations.switches.showFfp : props[0].translations.switches.showSystem;
    const [minimumOrderAmountDisplayChoice, setMinimumOrderAmountDisplayChoice] = useState('primary');

    return (
        <table className="table">
            <thead>
            <tr>
                <th>
                    {props[0].translations.tableHeaders.field}
                </th>

                <th>
                    {props[0].translations.tableHeaders.minimum}
                </th>

                <th>
                    {props[0].translations.tableHeaders.maximum}
                </th>

                <th>
                    {props[0].translations.tableHeaders.incrementStep}
                </th>

                <th>
                    {props[0].translations.tableHeaders.activeValue}
                </th>

                <th>
                    &nbsp;
                </th>
            </tr>
            </thead>

            <tbody>
            <tr>
                <td>
                    {props[0].translations.tableRows.amount}
                </td>

                {amountDisplayChoice === 'primary' ?
                    <td className="amountActiveValue">{amountInUse ? props[0].ffpSystemValues.amount_min : props[0].systemDefaults.amount.min}</td> :
                    <td className="amountPassiveValue">{amountInUse ? props[0].systemDefaults.amount.min : props[0].ffpSystemValues.amount_min}</td>}


                {amountDisplayChoice === 'primary' ?
                    <td className="amountActiveValue">{amountInUse ? props[0].ffpSystemValues.amount_max : props[0].systemDefaults.amount.max}</td> :
                    <td className="amountPassiveValue">{amountInUse ? props[0].systemDefaults.amount.max : props[0].ffpSystemValues.amount_max}</td>}

                {amountDisplayChoice === 'primary' ?
                    <td className="amountActiveValue">{amountInUse ? props[0].ffpSystemValues.amount_increment_step : props[0].systemDefaults.amount.increment_step}</td> :
                    <td className="amountPassiveValue">{amountInUse ? props[0].systemDefaults.amount.increment_step : props[0].ffpSystemValues.amount_increment_step}</td>}

                <td>{amountPrimaryLabel}</td>

                <td>
                        <Span
                            onMouseOver={() => {
                                setAmountDisplayChoice('secondary')
                            }}
                            onMouseLeave={() => {
                                setAmountDisplayChoice('primary')
                            }}
                        >
                            {amountSecondaryLabel}
                        </Span>
                </td>
            </tr>

            <tr>
                <td>
                    {props[0].translations.tableRows.minimumOrderAmount}
                </td>

                {minimumOrderAmountDisplayChoice === 'primary' ?
                    <td className="amountActiveValue">{minimumOrderAmountInUse ? props[0].ffpSystemValues.minimum_order_amount_min : props[0].systemDefaults.minimum_order_amount.min}</td> :
                    <td className="amountPassiveValue">{minimumOrderAmountInUse ? props[0].systemDefaults.minimum_order_amount.min : props[0].ffpSystemValues.minimum_order_amount_min}</td>}


                {minimumOrderAmountDisplayChoice === 'primary' ?
                    <td className="amountActiveValue">{minimumOrderAmountInUse ? props[0].ffpSystemValues.minimum_order_amount_max : props[0].systemDefaults.minimum_order_amount.max}</td> :
                    <td className="amountPassiveValue">{minimumOrderAmountInUse ? props[0].systemDefaults.minimum_order_amount.max : props[0].ffpSystemValues.minimum_order_amount_max}</td>}

                {minimumOrderAmountDisplayChoice === 'primary' ?
                    <td className="amountActiveValue">{minimumOrderAmountInUse ? props[0].ffpSystemValues.minimum_order_amount_increment_step : props[0].systemDefaults.minimum_order_amount.increment_step}</td> :
                    <td className="amountPassiveValue">{minimumOrderAmountInUse ? props[0].systemDefaults.minimum_order_amount.increment_step : props[0].ffpSystemValues.minimum_order_amount_increment_step}</td>}

                <td>{minimumOrderAmountPrimaryLabel}</td>

                <td>
                        <Span
                            onMouseOver={() => {
                                setMinimumOrderAmountDisplayChoice('secondary')
                            }}
                            onMouseLeave={() => {
                                setMinimumOrderAmountDisplayChoice('primary')
                            }}
                        >
                            {minimumOrderAmountSecondaryLabel}
                        </Span>
                </td>
            </tr>
            </tbody>
        </table>
    );
};

export default FfpSystemValidationRules;
