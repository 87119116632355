import React, { useState } from "react"
import queryString from "query-string"
import UserSearchPartial from "./UserSearchPartial";
import FfpSystemSelector from "../../FfpSystemSelector";

const FlightBuyerSearchForm = ({ ffpSystemId, ffpSystemName }) => {
    const parsedParams = queryString.parse(document.location.search)

    const [progress, setProgress] = useState(parsedParams.progress)
    const [from, setFrom] = useState(parsedParams.content_from || "")
    const [to, setTo] = useState(parsedParams.content_to || "")

    const [turnaRedirects, setTurnaRedirects] = useState(false)

    const renderProgressOptions = () => {
        return [
            ['', "Choose"],
            ["flight_search", "Flight Search"],
            ["flight_select", "Flight Select"],
            ["booking_method", "Booking Method"],
            ["select_option", "Delivery Choice"],
            ["select_passenger", "Select Passenger"],
            ["contact_information", "Contact Information"],
            ["order_detail", "Order Detail / Promo Code"],
            ["payment_method", "Payment Method"],
            ["done", "Done"]
        ].map((el, x) => {
            return <option key={x} value={el[0]}>{el[1]}</option>
        })
    }

    return <>
        <div className="col-md-2">
            <label>Progress</label>

            <select className="form-control" name="progress" value={progress} onChange={ev => setProgress(ev.target.value)}>
                {renderProgressOptions()}
            </select>
        </div>

        <div className="col-md-2">
            <label>Program</label>
            <FfpSystemSelector selectedId={ffpSystemId} selectedName={ffpSystemName} />
        </div>

        <div className="col-md-1">
            <label>From</label>
            <input name="content_from" className="form-control"
                value={from} onChange={ev => setFrom(ev.target.value)} style={{ textTransform: "uppercase" }} />
        </div>

        <div className="col-md-1">
            <label>To</label>
            <input name="content_to" className="form-control"
                value={to} onChange={ev => setTo(ev.target.value)} style={{ textTransform: "uppercase" }} />
        </div>

        <div className="col-md-1 form-check">
            <label htmlFor="redirects">Turna Redirects</label>&nbsp;
            <input id="redirects" type="checkbox" name="turna_redirects" className="form-check-input"
                value={turnaRedirects}
                defaultChecked={parsedParams.turna_redirects === 'true' ? true : false}
                onChange={ev => setTurnaRedirects(ev.target.checked)} />
        </div>
    </>
}

export default FlightBuyerSearchForm
