import flatpickr from "flatpickr"

require("flatpickr/dist/flatpickr.css");
require("flatpickr/dist/themes/airbnb.css");


document.addEventListener('turbolinks:load', () => {
    flatpickr("[data-behavior='flatpickr']", {

    })
});
