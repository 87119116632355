import React from "react";

function paymentProfileLabel(allowed) {
    if (allowed) {
        return <>
            <span className="text text-success"><i className="fa fa-check" aria-hidden="true"></i></span>&nbsp;
        </>;
    } else {
        return <>
            <span className="text text-danger"><i className="fa fa-times" aria-hidden="true"></i></span>&nbsp;
        </>;
    }
}

function activeOrders(orderCount) {
    if (orderCount === 0) {
        return <>
            <span className="text text-success"><i className="fa fa-tags" aria-hidden="true"></i> 0</span>&nbsp;
        </>;
    } else {
        return <>
            <span className="text text-danger"><i className="fa fa-tags" aria-hidden="true"></i> {orderCount}</span>&nbsp;
        </>;
    }
}

class MerchantPerformance extends React.Component {
    constructor(props) {
        super(props);

        this.userId = props.userId;

        this.state = { loading: true, respData: {} }
    }

    componentDidMount() {
        fetch(`/user_related_resources/merchant_performance?user_id=${this.userId}`)
            .then(response => response.json())
            .then(data => {
                this.setState({
                    loading: false,
                    found_in_pools: data.found_in_pools,
                    accepted_total: data.accepted_total,
                    won_total: data.won_total,
                    reject_total: data.reject_total,
                    payment_profile_allowed: data.has_approved_payment_profile,
                    active_order_count: data.active_order_count
                })
            });
    }

    render() {
        if (this.state.loading) {
            return <span>Loading...</span>
        }

        return <span onClick={() => alert("Cross: Don't have confirmed payment profile.\nTick: Have confirmed payment profile.\nWhite: This seller's wallet count of pool\nYellow: Accepted pool invitation count\nGreen: Won order pool count\nRed: Rejected order count")}>

            {paymentProfileLabel(this.state.payment_profile_allowed)}
            {activeOrders(this.state.active_order_count)}

            &nbsp;&nbsp;&nbsp;
            <span className="label label-default">{this.state.found_in_pools}</span>&nbsp;
            <span className="label label-warning">{this.state.accepted_total}</span>&nbsp;
            <span className="label label-success">{this.state.won_total}</span>&nbsp;
            <span className="label label-danger">{this.state.reject_total}</span>
        </span>
    }
}

export default MerchantPerformance;
