import React from "react";
import moment from "moment";
const _ = require("lodash");
import MerchantPerformance from "./MerchantPerformance";

class ApplicableWallet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hours: 12,
            buttonDisabled: false,
            pricePerMile: props.item.priceForMiles,
            delta: props.item.delta,
            tax: _.toNumber(props.tax),
            milesCount: _.toNumber(props.milesCount),
            paymentAmount: _.toNumber(props.paymentAmount),
            serviceFee: _.toNumber(props.serviceFee)
        };

        this.item = props.item;
        this.item.responseTime = this.state.hours
    }

    calculateSellerIncome() {
        const milesFactor = this.state.milesCount / 1000;
        return this.state.tax + (milesFactor * this.state.pricePerMile)
    }

    calculateTotalServiceFee() {
        const sellerIncome = this.calculateSellerIncome()

        return this.state.paymentAmount - sellerIncome;
    }

    calculateDelta() {
        return this.calculateTotalServiceFee() - this.state.serviceFee
    }

    handleHourChange(hour) {
        this.item.responseTime = hour
        this.setState({ hours: hour })
    }

    handlePricePerMileChange(price) {
        this.setState({
            pricePerMile: price
        })
    }

    handleClick() {
        this.props.changeLoading(true)
        this.setState({ buttonDisabled: true })

        fetch(`/order_pools/${this.props.orderPoolId}/order_pool_items?wallet_id=${this.item.id}&merchant_respond_timeout=${this.state.hours}&price_per_mile=${this.state.pricePerMile}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': this.props.authenticityToken
            },
        }).then(resp => resp.json()).then(data => {
                if (data.status === "ok") {
                    this.props.changeLoading(false)

                    const item = {...this.props.item}

                    item.priceForMiles = this.state.pricePerMile
                    item.sellerIncome = this.calculateSellerIncome()
                    item.totalServiceFee = this.calculateTotalServiceFee()
                    item.delta = this.calculateDelta()

                    this.props.addToPool(item)
                    this.setState({ buttonDisabled: false })
                } else {
                    alert("Unable to add to pool.")
                    this.props.changeLoading(false)
                    this.setState({ buttonDisabled: false })
                }}).catch(() => {
            alert("Unable to add to pool.")
            this.props.changeLoading(false)
            this.setState({ buttonDisabled: false })
        })
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        const {id, userId, seller, availableAmount} = this.item;
        const ts = moment(new Date(this.item.updatedAt * 1000)).format("YYYY-MM-DD");

        return <tr key={id}>
            <td>
                <a href={`/auth/users/${userId}`} target="_blank">{seller}</a>

                <MerchantPerformance userId={userId} />
            </td>
            <td>{this.item.applicationCount}</td>
            <td>{ts}</td>
            <td>{this.numberWithCommas(availableAmount)}</td>
            <td>
                <div className="input-group">
                    <span className="input-group-addon">$</span>
                    <input id="input_group_a" aria-describedby="input_group_abc" type="number" step="0.01"
                           value={this.state.pricePerMile} className="form-control"
                           onChange={(e) => {
                               this.handlePricePerMileChange(e.target.value)
                           }} />
                </div>

                {/*formatter.format(priceForMiles)*/}
            </td>
            <td>
                {formatter.format(this.calculateSellerIncome())}
            </td>

            <td>
                {formatter.format(this.calculateTotalServiceFee())}
            </td>

            <td>
                {formatter.format(this.calculateDelta())}
            </td>

            <td>
                <div className="input-group">
                    <input min={2} max={36} step={1}
                           type="number"
                           value={this.state.hours}
                           onChange={(e) => {this.handleHourChange(e.target.value)}}

                           className="form-control" />
                    <span className="input-group-addon">hours</span>
                </div>
            </td>
            <td>
                <button className="btn btn-success"
                        onClick={() => this.handleClick() }
                        disabled={this.state.buttonDisabled}
                >Add to Pool</button>
            </td>
        </tr>
    }
}

export default ApplicableWallet
