import React from "react";

const FfpSystemCard = ({ffpSystem}) => {
    return (
        <div className="col-sm-3 col-md-2">
            <img src={ffpSystem.logo} width="60px" height="60px"/>

            <div className="caption">
                <h4>{ffpSystem.name}</h4>
            </div>
        </div>
    );
};

export default FfpSystemCard;
