import React from "react";
import FfpSystemCard from "./FfpSystemCard";

const VisibleToFfpSystems = ({announcementRelations, ffpSystems}) => {
    const availableFfpSystem = ffpSystems.filter((item) => {
        return announcementRelations.map(item => item.ffp_system_id.toString()).includes(item.id.toString());
    });

    return (
        <div className="row">
            {availableFfpSystem.map((ffpSystem) => {
                return <FfpSystemCard key={ffpSystem.id} ffpSystem={ffpSystem} />
            })}
        </div>
    );
};

export default VisibleToFfpSystems;
