import React, { useState } from "react"
import ListView from "./announcement/ListView";
import EditView from "./announcement/EditView";


const FfpSystemAnnouncementRelation = ({announcementId, translations}) => {
    const [selectedMenu, setSelectedMenu] = useState('list-view');

    return (
        <div>
            {selectedMenu === 'list-view' ? <ListView announcementId={announcementId} translations={translations.VisibleForAll} /> : <EditView translations={translations.EditView} announcementId={announcementId} />}

            <div>
                <button
                    disabled={selectedMenu === 'list-view'}
                    onClick={() => { setSelectedMenu('list-view') }}
                    className="btn btn-primary"
                >
                    {translations.list}
                </button>

                &nbsp;

                <button
                    disabled={selectedMenu === 'edit-view'}
                    onClick={() => { setSelectedMenu('edit-view') }}
                    className="btn btn-primary"
                >
                    {translations.edit}
                </button>
            </div>
        </div>
    );
};

export default FfpSystemAnnouncementRelation;
